import { gql } from '@apollo/client';


export const GET_EE = gql`
    query EE ( $id : ID! ){
        exhibitionEvent(id: $id) {
            id
            title
            status
            aquise_number
            construction_start_at
            construction_end_at
            running_start_at
            running_end_at
            deconstruction_start_at
            deconstruction_end_at
            organizer_id
            organizer {
                id
                title
                status
                et_default_id
                exhibitionTypes {
                    id
                    title
                    status
                    organizer_id
                }
            }
            exhibition_type_id
            team_id
            team {
                id
                title
            }
            project_manager_id
            projectManager{
                id
                name
                surname
            }
            construction_manager_id
            constructionManager{
                id
                name
                surname
            }
        }
    }
`;


export const GET_EXHIBITIONS = gql`
    query GetExhibitions (
        $text    : String
        $where   : QueryExhibitionEventsCursorWhereWhereConditions
        $orderBy : [QueryExhibitionEventsCursorOrderByOrderByClause!]
        $after   : String
    ){
        exhibitionEventsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 100
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                    status
                    aquise_number
                    stand_total
                    stand_inprogress_total
                    stand_completed_total
                    construction_start_at
                    construction_end_at
                    running_start_at
                    running_end_at
                    deconstruction_start_at
                    deconstruction_end_at
                    organizer_id
                    area
                    organizer {
                        id
                        title
                        status
                        et_default_id
                        exhibitionTypes {
                            id
                            title
                            status
                            organizer_id
                        }
                    }
                    exhibition_type_id
                    team_id
                    team {
                        id
                        title
                    }
                    project_manager_id
                    projectManager{
                        id
                        name
                        surname
                    }
                    construction_manager_id
                    constructionManager{
                        id
                        name
                        surname
                    }
                }
            }
        }
    }
`;


export const GET_EE_HALLS = gql`
    query GetHalls (
        $text    : String
        $where   : QueryHallsWhereWhereConditions
        $orderBy : [QueryHallsOrderByOrderByClause!]
        $page    : Int
    ){
        halls(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            page    : $page
        ) {
            paginatorInfo {
                currentPage
                total
                hasMorePages
            }
            data {
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                hall_plan_img
            }
        }
    }
`;

export const GET_EE_HALL = gql`
    query GetHall ( $id : ID! ){
        hall(id : $id) {
            id
            title
            ee_id
            number
            stands_count
            hall_plan
            hall_plan_img
        }
    }
`;
