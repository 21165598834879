import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_TEAMS } from "graphql/query/team-q.gql";

import { Icons } from "components/layout";


const { Option } = Select;

const TeamSelect : React.FC = (props) : JSX.Element => {

    const { data, loading } = useQuery( GET_TEAMS, {
        variables : {
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first"
    });

    const {
        teamsCursor : {
            edges : teams = []
        } = {}
    } = data || {};

    return(
        <Select
            loading      = { loading }
            defaultValue = { ""! }
            suffixIcon    = { <Icons.Arrow /> }
            { ...props }
        >
            <Option key={ 0 } value={ "" }>Not selected</Option>

            { teams.map(
                (
                    { node : { id, title } } :
                    { node : { id: number, title: string } }
                ) : JSX.Element => {

                    return(
                        <Option key={ id } value={ id } >
                            { title }
                        </Option>
                    );
                }
            )}
        </Select>
    );
};

export default React.memo(TeamSelect);