import React, { JSX } from "react";

import Localize from "components/service/Localize";

import type { TStandTypeOrientations } from "../stand-type-types";


interface IStandTypeOrientationsProps{
    orientations : TStandTypeOrientations[]
}

const StandTypeOrientationsList : React.FC<IStandTypeOrientationsProps> = ({ orientations }) : JSX.Element => {

    return(
        <ul className="stand-type-list-orientations">
            { orientations.map(
                ( { id, img_path , orientation_key } ) => {
                    return(
                        <li key={ id }>
                            <span className="img-wrap">
                                <img src={img_path} alt={`${orientation_key} image`}/>
                            </span>
                            <Localize wrap>
                                {`VARIABLE_ITEM.${orientation_key}`}
                            </Localize>
                        </li>
                    );
                })
            }
        </ul>
    );
};

export default StandTypeOrientationsList;