import React, { JSX } from "react";

import { ECheckListType } from "../check-list-types";
import CheckListHelpers from "../helpers";
import { DateTimePicker } from "components/service";

import type { ICheckListItem, IFieldProps } from "../check-list-types";
import type { DatePickerProps } from "antd";


const CheckListDatePickerField : React.FC<IFieldProps> = ({
        value,
        handleMutate,
        loading,
        type = ECheckListType.DATE,
        placeholder = "—",
        multiField
    }) : JSX.Element => {

    const {
        date,
        withTime,
        formatType
    } = CheckListHelpers.getDateData(value, type);

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {

        if(!loading && value !== dateString && handleMutate !== undefined){
            let listValue = dateString;

            if(multiField){
                listValue = JSON.stringify({
                    ...multiField.structure,
                    [multiField.current] : dateString,
                });
            }

            handleMutate<Pick<ICheckListItem, 'list_value'>>(
                { list_value : listValue }
            );
        }
    };

    return(
        <DateTimePicker
            format        = { formatType }
            withTime      = { withTime }
            placeHolder   = { placeholder }
            allowClear    = { true }
            inputReadOnly = { true }
            defaultValue  = { date }
            onChange      = { onChange }
        />
    );
};


export default CheckListDatePickerField;