import React, { JSX } from "react";

import { Button } from "antd";
import { DocumentNode } from "graphql";

import { ATTACHMENT_DELETE } from "graphql/mutation/common-m.gql";

import { localizeByKey } from "../../service/Localize";
import { Icons } from "components/layout";
import { DeleteButton } from "components/service";

import type { TAttachment, TID } from "graphql/type/common-gql-types";


interface ITaskFileListProps {
    files      ?: TAttachment[]
    withDelete ?: boolean
    onDelete   ?: ( fileId : TID ) => void
    noRequest  ?: boolean
    linkClass  ?: string
    query      ?: DocumentNode | "No request"
}

const TaskFileList : React.FC<ITaskFileListProps> = ({
        files = [],
        withDelete = false,
        onDelete  = () => {},
        noRequest = false,
        linkClass = "",
    }): JSX.Element | null => {

    if(!files.length){
        return null;
    }

    return (
        <div className="task-file-list-wrap">
            <ul  className="task-file-list">
                { files.map(
                    ( file ) => {
                        return (
                            <li
                                key       = { file.id }
                                className = "task-file-list-item"
                            >
                                <a className = { `link-text ${ linkClass }` }
                                   href      = { file.path }
                                   target    = "_blank"
                                >
                                    <Icons.Documents type={file.extension}/>
                                    <span>{ file.original_name }.{file.extension}</span>
                                </a>
                                { withDelete &&
                                    <div className = "action">
                                        { !noRequest ?
                                            <DeleteButton
                                                id          = { file.id }
                                                buttonType  = { "text" }
                                                iconName    = { "Cross" }
                                                query       = { ATTACHMENT_DELETE }
                                                onSuccess   = { (_, cache) => {
                                                    cache.evict({
                                                        id : cache.identify(
                                                            { id : file.id, __typename : "Attachment" }
                                                        )
                                                    });

                                                    onDelete(file.id);
                                                }}
                                                confirmText = {
                                                    localizeByKey(
                                                        'GLOBAL.Modal_Text_DeleteFile',
                                                        {
                                                            br : `\n`,
                                                            file : `${file.original_name}.${file.extension}`
                                                        }
                                                    )
                                                }
                                            /> :
                                            <Button
                                                className = { `delete-button` }
                                                type      = { 'text' }
                                                onClick   = {
                                                    () => {
                                                        onDelete(file.id);
                                                    }
                                                }
                                            >
                                                <Icons.Cross />
                                            </Button>
                                        }
                                    </div>
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default TaskFileList;