import React, { JSX } from "react";

import type { TWhereCondition } from "graphql/type/common-gql-types";


export enum EExhibitionStatus {
    NEW        = "new",
    IN_PROCESS = "in_process",
    FINISHED   = "finished",
}

type TExhibitionStatusOptions<T = boolean> = { [key in keyof typeof EExhibitionStatus]: T };

export const exhibitionStatusMap: TExhibitionStatusOptions<string> = {
    NEW : "new",
    IN_PROCESS : "in_process",
    FINISHED : "finished",
};

export interface IExhibitionsTableDataType {
    key                : React.Key
    title              : JSX.Element
    standCount         : JSX.Element
    aquiseNumber       : JSX.Element | string
    status             : JSX.Element
    constructionDate   : JSX.Element
    runningDate        : JSX.Element
    deconstructionDate : JSX.Element
    projectManager     : JSX.Element | string
    organizer          : JSX.Element
    area               : number
    team               : JSX.Element
    action             : JSX.Element
}

export interface IExhibitionHallTableDataType {
    key                : React.Key
    title              : JSX.Element
    number             : JSX.Element
    standsAmount       : string | number
    hallPlan           : JSX.Element | string
    action             : JSX.Element
}

export type TExhibitionFilterGroups = 'where';
export type TExhibitionFilterFields = Partial<Record<`${EExhibitionFilterFieldTypes}`, TWhereCondition>>;

export type TExhibitionFilters = Partial<Record<TExhibitionFilterGroups, TExhibitionFilterFields>> & Record<'text', string>;

export enum EExhibitionFilterFieldTypes  {
    NONE = 'none',
    STATUS = 'status',
    ORGANIZER = 'organizer',
    EXHIBITION_TYPE = 'exhibitionType',
    PROJECT_MANAGER = 'projectManager',
    TEAM = 'team'
}