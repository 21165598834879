import type { TWhereCondition } from "graphql/type/common-gql-types";


export type TGraphicsFilterGroups = 'where' | 'whereStand';
export type TGraphicsFilterFields = Partial<Record<`${EGraphicsFilterFieldTypes}`, TWhereCondition>>;

export type TGraphicsFilters = Partial<Record<TGraphicsFilterGroups, TGraphicsFilterFields>> & Record<'text', string>;

export enum EGraphicsFilterFieldTypes  {
    NONE = 'none',
    COMPANY_NAME = 'companyName',
    HALL = 'hall',
    BLOCK = 'block',
    STAND_NUMBER = 'standNumber',
    STATUS = 'status',
    MP_STATUS = 'mpStatus'
}