import React, { JSX }  from "react";

import { Select } from "antd";
import { SelectProps } from "rc-select/lib/Select";

import { EStandFilterFieldTypes } from "../stand-types";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";


type TStandListFilterSelectProps = {
    filteredTypes : EStandFilterFieldTypes[]
    defaultValue  : EStandFilterFieldTypes
    onChange     ?: SelectProps['onChange']
};

const { Option } = Select;

const StandListFilterTypesSelect : React.FC<TStandListFilterSelectProps> = (
    {
        filteredTypes,
        defaultValue ,
        ...props
    }): JSX.Element => {

    return(
        <Select
            className     = { `stand-list-filter-type-select` }
            suffixIcon    = { <Icons.Arrow /> }
            defaultValue  = { defaultValue }
            { ...props }
        >
            <Option key={ EStandFilterFieldTypes.NONE } value={ EStandFilterFieldTypes.NONE  }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Option>
            {(Object.keys(EStandFilterFieldTypes) as Array<keyof typeof EStandFilterFieldTypes>)
                    .filter( ( item ) => {
                        return !filteredTypes.includes(EStandFilterFieldTypes[item]);
                    } )
                    .map(
                (key) => {
                    if(key === 'NONE') return null;

                    return(
                        <Option key={ key } value={ EStandFilterFieldTypes[key] }>
                            <Localize>{ `STAND.Select_Option_Filter_${ EStandFilterFieldTypes[key] }` }</Localize>
                        </Option>
                    );
                })
            }
        </Select>
    );
};

export default StandListFilterTypesSelect;