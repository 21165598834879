import StandCadStatusForm from "./Stand-cad-status-form";
import StandChangeTypeSizeForm from "./Stand-change-type-size-form";
import StandEditForm from "./Stand-edit-form";
import StandListFilterForm from "./Stand-list-filter-form";

import "./stand-forms.scss";


const StandForms = {
    Edit : StandEditForm,
    CadStatus : StandCadStatusForm,
    ChangeTypeSize : StandChangeTypeSizeForm,
    ListFilter : StandListFilterForm
};

export default StandForms;