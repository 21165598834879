import { EGraphicsFilterFieldTypes } from "../graphics-types";

import type { TGraphicsFilterGroups, TGraphicsFilters } from "../graphics-types";
import type { TFilterFieldItem, TFilterFields } from "common/types";
import type { TWhereCondition } from "graphql/type/common-gql-types";


const graphicsFilterParse = (filters : TGraphicsFilters) : TFilterFieldItem<EGraphicsFilterFieldTypes>[] => {

    const fields : TFilterFieldItem<EGraphicsFilterFieldTypes>[] = [];

    (Object.keys(filters) as Array<TGraphicsFilterGroups | 'text'>).forEach( ( group ) => {

        if(group !== 'text'){
            const filterGroup = filters[group] as Omit<TFilterFields, 'text'>;

            Object.keys(filterGroup).forEach(( filterItemKey ) => {

                const item = filterGroup[filterItemKey] as TWhereCondition;
                let type;

                switch(`${ group }_${filterItemKey}`){
                    case 'where_status'           : type = EGraphicsFilterFieldTypes.STATUS; break;
                    case 'where_mpStatus'         : type = EGraphicsFilterFieldTypes.MP_STATUS; break;
                    case 'whereStand_hall'        : type = EGraphicsFilterFieldTypes.HALL; break;
                    case 'whereStand_block'       : type = EGraphicsFilterFieldTypes.BLOCK; break;
                    case 'whereStand_companyName' : type = EGraphicsFilterFieldTypes.COMPANY_NAME; break;
                    case 'whereStand_standNumber' : type = EGraphicsFilterFieldTypes.STAND_NUMBER; break;
                }

                const value = typeof item.value === 'string' ? item.value.replace('%', '') : item.value;

                fields.push({
                    key : Date.now().toString(),
                    type : type!,
                    saveable : true,
                    label : item.label,
                    value : value as string
                });
            });
        }
    });

    return fields;
};

const graphicsFilterBuild = (fields : TFilterFieldItem<EGraphicsFilterFieldTypes>[]): Omit<TGraphicsFilters, 'text'> => {

    const where: TGraphicsFilters["where"] = {};
    const whereStand: TGraphicsFilters["whereStand"] = {};


    fields.forEach( (item) => {

        if(item.saveable && item.value !== '') {
            const filter : TWhereCondition = {
                column : '',
                operator : "EQ",
                value : item.value,
                label : item.label
            };

            switch( item.type ){
                case EGraphicsFilterFieldTypes.STATUS:
                    where[item.type] = { ...filter, column : 'STATUS' };
                break;
                case EGraphicsFilterFieldTypes.MP_STATUS:
                    where[item.type] = { ...filter, column : 'MP_STATUS' };
                break;
                case EGraphicsFilterFieldTypes.HALL:
                    whereStand[item.type] = { ...filter, column : 'HALL_ID' };
                break;
                case EGraphicsFilterFieldTypes.COMPANY_NAME:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'COMPANY_NAME',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case EGraphicsFilterFieldTypes.BLOCK:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'BLOCK',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case EGraphicsFilterFieldTypes.STAND_NUMBER:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'STAND_NUMBER',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
            }
        }
    });

    return {
        ...(Object.keys(where).length > 0 && { where }),
        ...(Object.keys(whereStand).length > 0 && { whereStand }),
    };
};


const graphicsFilterHelper = {
    parse : graphicsFilterParse,
    build : graphicsFilterBuild
};

export default graphicsFilterHelper;