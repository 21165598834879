import React, { JSX } from "react";

import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { TASK_COMMENT_CREATE } from "graphql/mutation/task-m.gql";

import { TTaskComment } from "../../../graphql/type/task-gql-types";
import TaskFields from "../fields";
import { Icons } from "components/layout";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";

interface ITaskCommentEditFormProps {
    taskId : TID
    comments: TTaskComment[]
    setComments: ( newComments : TTaskComment[] ) => void
}

const { TextArea } = Input;

const TaskCommentEditForm : React.FC<ITaskCommentEditFormProps> = (
    {
        taskId,
        setComments,
        comments,
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ taskCommentCreate, { loading } ] = useMutation(
        TASK_COMMENT_CREATE,
        {
            update(cache, { data }){
                const { "taskCommentCreate" : {
                    label,
                    message,
                    taskComment,
                }  } = data;

                nSuccess(label, message);

                cache.modify({
                    id : cache.identify({ __ref : `Task:${ taskId }` }),
                    fields : {
                        total_comment(currentCount) {
                            return currentCount + 1;
                        },
                    },
                });

                setComments([
                    taskComment,
                    ...comments
                ]);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const isCreate = true;
    const [ form ] = Form.useForm();

    return(
        <Form
            className = { "task-comment-edit-form inner-forms" }
            form      = { form }
            layout    = "vertical"
            onFinish={(values) => {
                if (!loading && values.description.length > 1) {
                    taskCommentCreate({
                        variables : {
                            input : {
                                task_id : taskId,
                                description : values.description,
                               ...(values.file && { file : values.file?.originFileObj })
                            }
                        }
                    }).then( () => {
                        form.resetFields();
                    } ).catch(nError);
                }
            }}
        >
            <Form.Item
                name  = "description"
            >
                <TextArea
                    placeholder = "Leave comment..."
                    autoSize    = {{ minRows : 4, maxRows : 6 }}
                />
            </Form.Item>
            <div className="form-actions">
                <Form.Item
                    name="file"
                    valuePropName="file"
                    getValueFromEvent={ (file) => file }
                >
                    <TaskFields.CommentFile />
                </Form.Item>

                <div>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        <Icons.Plus loading={loading}/>
                        {isCreate ? 'Add comment' : 'Save comment'}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default TaskCommentEditForm;