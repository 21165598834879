import React, { JSX, useState } from "react";

import { subFieldCount } from "common/utils";
import Exhibition from "components/exhibition";
import type { TExhibitionFilters } from "components/exhibition/exhibition-types";
import { Blocks, Fields, Modals } from "components/layout";
import { LinkWithIcon } from "components/service";
import Localize from "components/service/Localize";


const ExhibitionsPage : React.FC = () : JSX.Element => {

    const [ filters, setFilters ] = useState<TExhibitionFilters>({ text : "" });

    return(
        <div className="page exhibitions-page">
            <Blocks.PageHeader
                title       = { <Localize>EXHIBITION.List_PageTitle_Exhibitions</Localize> }
                hideEEBlock = { true }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                        <Modals.Filters
                            filtersCount = { subFieldCount(filters) }
                        >
                            <Exhibition.Forms.ListFilter
                                filters    = { filters }
                                setFilters = { setFilters }
                            />
                        </Modals.Filters>
                    </>}
                    actions = {<>
                        <LinkWithIcon route={ "EXHIBITION_ITEM_CREATE" } >
                            <Localize>
                                EXHIBITION.Button_Label_AddExhibition
                            </Localize>
                        </LinkWithIcon>
                    </>}
                />
                <Exhibition.Tables.All
                    filters={ filters }
                />
            </div>
        </div>

    );
};

export default ExhibitionsPage;