import React, { JSX }  from "react";

import { Select } from "antd";
import { SelectProps } from "rc-select/lib/Select";

import { EExhibitionFilterFieldTypes } from "../exhibition-types";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";


type TExhibitionListFilterSelectProps = {
    filteredTypes : EExhibitionFilterFieldTypes[]
    defaultValue  : EExhibitionFilterFieldTypes
    onChange     ?: SelectProps['onChange']
};

const { Option } = Select;

const ExhibitionListFilterTypesSelect : React.FC<TExhibitionListFilterSelectProps> = (
    {
        filteredTypes,
        defaultValue ,
        ...props
    }): JSX.Element => {

    return(
        <Select
            className     = { `exhibition-list-filter-type-select` }
            suffixIcon    = { <Icons.Arrow /> }
            defaultValue  = { defaultValue }
            { ...props }
        >
            <Option key={ EExhibitionFilterFieldTypes.NONE } value={ EExhibitionFilterFieldTypes.NONE  }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Option>
            {(Object.keys(EExhibitionFilterFieldTypes) as Array<keyof typeof EExhibitionFilterFieldTypes>)
                .filter( ( item ) => {
                    return !filteredTypes.includes(EExhibitionFilterFieldTypes[item]);
                } )
                .map(
                    (key) => {
                        if(key === 'NONE') return null;

                        return(
                            <Option key={ key } value={ EExhibitionFilterFieldTypes[key] }>
                                <Localize>{ `EXHIBITION.Select_Option_Filter_${ EExhibitionFilterFieldTypes[key] }` }</Localize>
                            </Option>
                        );
                    })
            }
        </Select>
    );
};

export default ExhibitionListFilterTypesSelect;