import React, { JSX } from "react";

import { Collapse } from "antd";

import Equipment from "components/equipment";
import Localize from "components/service/Localize";

import type { TID } from "graphql/type/common-gql-types";
import type { TEquipmentOrderType } from "graphql/type/equipment-gql-types";


interface StandEquipmentOrderProps {
    standId : TID
    ee      : any
    type    : TEquipmentOrderType
}

const StandEquipmentOrderBlock : React.FC<StandEquipmentOrderProps> = (
    {
        standId,
        type = 'default',
        ee,
    }): JSX.Element => {

    return(
        <>
            <Collapse
                className        = "stand-equipment-order-block"
                defaultActiveKey = { type }
                ghost
                expandIcon={
                    () => <button className="plus-minus-toggle" />
                }
                items={[
                    {
                        key : type,
                        label :
                            <>
                                <Localize
                                    wrap
                                    tag       = "h4"
                                    className = "inverted"
                                >
                                    { `EQUIPMENT.Order_CollapseLabel_${ type }`}
                                </Localize>
                                { type !== 'default' &&
                                    <div
                                        className = "action"
                                        onClick   = { (e) => { e.stopPropagation(); } }
                                    >
                                        <Equipment.Modals.SelectDrawer
                                            standId      = { standId }
                                            etId         = { ee.exhibition_type_id }
                                            orgId        = { ee.organizer_id }
                                            type         = { type }
                                            isSubstitute = { false }
                                        />
                                    </div>
                                }
                            </>,
                        children :
                            <Equipment.Tables.Orders
                                standId = { standId }
                                type    = { type }
                                etId    = { ee.exhibition_type_id }
                                orgId   = { ee.organizer_id }
                            />
                    }
                ]}
            />
        </>
    );
};

export default StandEquipmentOrderBlock;