import { gql, TypedDocumentNode } from "@apollo/client";

import { IQueryVariables, TVariableListCursorData } from "../type/common-gql-types";


export const GET_VARIABLE_LISTS: TypedDocumentNode<{variableListsCursor: TVariableListCursorData}, IQueryVariables > = gql`
    query VariableListsCursor (
        $text    : String
        $where   : QueryVariableListsCursorWhereWhereConditions
        $orderBy : [QueryVariableListsCursorOrderByOrderByClause!]
        $after   : String
    ){
        variableListsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 500
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    group_name
                    slug
                    value
                    img_path
                }
            }
        }
    }
`;
