import React, { JSX } from "react";

import { localizeByKey } from "components/service/Localize";

import type { TStandData } from "graphql/type/stand-gql-types";


interface IStandPageTitleProps {
    stand: TStandData
}

const StandPageTitle : React.FC<IStandPageTitleProps> = ({ stand }) : JSX.Element => {

    const {
        company_name,
        hall_title,
        stand_number,
        block } = stand;

    const titleItems = [];

    if(hall_title)   titleItems.push(localizeByKey('STAND.Page_Title_Hall', { hallTitle : hall_title }));
    if(stand_number) titleItems.push(localizeByKey('STAND.Page_Title_Stand', { standNumber : stand_number }));
    if(block)        titleItems.push(localizeByKey('STAND.Page_Title_Block', { block : block }));

    return (
        <>
            { company_name }
            { titleItems.length &&
                <span>({ titleItems.join(', ') })</span>
            }
        </>
    );
};

export default StandPageTitle;