import React, { JSX } from "react";

import { Button } from "antd";

import Localize from "../../service/Localize";
import { Blocks, Icons } from "components/layout";

interface IStandTypeNotesBlockProps {
    standTypeId : string
}

const StandTypeNotesBlock : React.FC<IStandTypeNotesBlockProps> = (/*{ standTypeId }*/) : JSX.Element => {

    //console.log(standTypeId);

    return(
        <Blocks.Card
            Header={"Notes"}
            Action={
                <Button type="text" className="blue">
                    <Icons.Plus/>
                    Add note
                </Button>
            }
        >
            <div className="no-data">
                <Localize
                    wrap tag="p"
                    className="label"
                >
                    No Notes
                </Localize>
            </div>
        </Blocks.Card>
    );
};

export default StandTypeNotesBlock;