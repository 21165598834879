import React, { JSX } from "react";

import { useOutletContext } from "react-router-dom";

import Localize from "components/service/Localize";


const ExhibitionChangeBlock : React.FC = () : JSX.Element | null => {

    const { ee } = useOutletContext<{ee : any}>();

    if(!ee) return null;

    return (
        <div className="exhibition-selector">
            <Localize wrap className="pre-text">
                GLOBAL.CurrentExhibition_PreText_YouAreWorkingOn
            </Localize>
            <span className="current-ee">
                { ee.title }
            </span>
        </div>
    );
};


export default ExhibitionChangeBlock;