import React, { JSX, useState } from "react";

import { Button } from "antd";

import { Icons } from "components/layout";
import Localize, { localizeByKey } from "components/service/Localize";

import { TFilterFieldItem } from "common/types";


type TFilterFormProps<FilterFields, FieldTypes> = {
    filterFields       : TFilterFieldItem<FilterFields>[],
    fieldTypes         : FieldTypes
    defaultFiledType   : any
    translationKey     : string
    onReset            : () => void
    onApply            : ( fields : TFilterFieldItem<FilterFields>[] ) => void
    readonly children  : ( props : {
        fieldSettings : any
        fieldItem : Partial<TFilterFieldItem<FilterFields>>
        filteredTypes : (string | FilterFields)[]
        fields : TFilterFieldItem<FilterFields>[]
    }) => JSX.Element
};

const FilterFormBlock  = <FilterFields, FieldTypes extends Record<string | 'none', string|number>>(
    {
        filterFields,
        fieldTypes,
        defaultFiledType,
        translationKey,
        onReset,
        onApply,
        children
    } : TFilterFormProps<FilterFields, FieldTypes>) : JSX.Element => {

    const [
        fields,
        setFields
    ] = useState<TFilterFieldItem<FilterFields>[]>( filterFields );

    const filteredTypes = fields.map(
        (item) =>
            item.type === 'none' && item.value ? item.value : item.type)
        .filter(
            (item, idx, items) => idx === items.indexOf(item)
        );

    const typesCount = Object.keys(fieldTypes).length - (filteredTypes.includes(defaultFiledType) ? 0 : 1);

    return(
        <div className="filter-form-block">
            <div className="add-filter-wrap">
                <Button
                    type     = "primary"
                    disabled = { filteredTypes.length >= typesCount }
                    onClick  = {
                        () => {
                            setFields([
                                ...fields ,
                                {
                                    key : Date.now().toString(),
                                    type : defaultFiledType,
                                    label : '',
                                    value : '',
                                    saveable : false
                                }
                            ]);
                        }
                    }
                >
                    <Icons.Plus />
                    <Localize wrap>Add Filter</Localize>
                </Button>
            </div>
            <div className="filter-field-list">
                { fields.map(
                    ({ type , value, label, key }, idx) => {
                        const itemKey = `${ key }_${ idx }`;

                        const fieldSettings = {
                            key : itemKey,
                            fields,
                            setFields,
                            itemKey,
                            label : localizeByKey(
                                type !== 'none' ?
                                    `${translationKey}_${ type }` :
                                    'GLOBAL.Field_Label_ChooseFilter'
                            ),
                            isFieldSelector : type === defaultFiledType,
                            onChange : (result : string |  React.ChangeEvent<HTMLInputElement>, option : any) => {
                                setFields(
                                    fields.map((item, fieldIdx) => {
                                        return fieldIdx === idx ?
                                            {
                                                ...item,
                                                value : typeof result === 'string' ? result : result.target.value,
                                                ...(option && { label : option.label })
                                            } : item;
                                    })
                                );
                            }
                        };

                        return children({
                            fieldSettings,
                            fieldItem : {
                                type,
                                value,
                                label
                            },
                            filteredTypes,
                            fields,
                        });
                    }
                )}
            </div>
            <div className="form-actions">
                <Button
                    type     = "default"
                    onClick  = {
                        () => {
                            setFields([]);
                            onReset();
                        }
                    }
                >
                    <Localize wrap>Reset</Localize>
                </Button>
                <Button
                    type     = "primary"
                    disabled = {
                        (fields.map((item) => item.type)
                            .filter((item, idx, items) => idx === items.indexOf(item))
                            .filter((item) => item !== 'none')).length < 1
                    }
                    onClick  = { () => onApply(fields) }
                >
                    <Localize wrap>Apply</Localize>
                </Button>
            </div>
        </div>
    );
};

export default FilterFormBlock;