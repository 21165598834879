import React, { JSX } from "react";

import Localize from "components/service/Localize";


interface GraphicsOrderPageActionsBlockProps {
    totalOrder ?: number
    children   ?: React.ReactNode
}

const GraphicsOrderPageActionsBlock : React.FC<GraphicsOrderPageActionsBlockProps> = (
    {
        totalOrder = 0,
        children
    }) : JSX.Element => {
    
    return (
        <div className="graphics-order-page-actions">
            <Localize
                wrap tag   = "h3"
                totalOrder = { totalOrder }
            >
                {  totalOrder === 1 ?
                    `GRAPHICS.Text_TotalOrder` : `GRAPHICS.Text_TotalOrders`
                }
            </Localize>
            <div className="actions">
                { children }
            </div>
        </div>
    );
};

export default GraphicsOrderPageActionsBlock;