import type { TWhereCondition } from "graphql/type/common-gql-types";


export interface IStandTypeSelect {
    id              : number
    title           : string
    navision_number : string
    group_id        : string
    price           : string
    size_min        : number
    size_max        : number
    orientations    : any[]
    getImages : [{
        "id"          : number
        "title"       : string
        "server_name" : string
        "sizes": {
            "original"   : string
            "200x200"   ?: string
            "1024x1024" ?: string
        },
    }]
}

export interface IStandSelect {
    id                 : number
    company_name       : string
    hall_id            : number
    hall_title         : string
    block              : string
    stand_number       : string
    proauf             : string
    stand_type_group   : number
    orientation_key    : string
    size_width         : number
    size_height        : number
    square_meter       : number
    flooring_id        : number
    flooring_title     : string
    project_manager_id : number
}

export type TStandFilterGroups = 'where'| 'whereCad' | 'whereTasks';
export type TStandFilterFields = Partial<Record<`${EStandFilterFieldTypes}`, TWhereCondition>>;

export type TStandFilters = Partial<Record<TStandFilterGroups, TStandFilterFields>> & Record<'text', string>;

export enum EStandFilterFieldTypes  {
    NONE = 'none',
    HALL = 'hall',
    BLOCK = 'block',
    PROJECT_MANAGER = 'projectManager',
    AUTOCAD_ENGINEER = 'autocadEngineer',
    CAD_STATUS = 'cadStatus',
    TASK_IMPORTANCE = 'taskImportance',
    TASK_STATUS = 'taskStatus',
    TASK_TYPE = 'taskType',
    TASK_ASSIGNED_TO = 'taskAssignedTo',
}
