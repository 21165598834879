import React, { JSX }  from "react";

import { useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { GET_EE_HALL } from "graphql/query/exhibition-q.gql";

import Exhibition from "components/exhibition";
import { Loader } from "components/request-result";


const ExhibitionHallSubPage : React.FC = () : JSX.Element => {

    const { eeId, hallId } = useParams(),
          isCreate = hallId === "create";

    const { data : { hall } = { },
        loading,
    } = useQuery( GET_EE_HALL, {
        variables : { id : hallId },
        skip : isCreate,
        fetchPolicy : "cache-first"
    });

    const span = {
        left : { xxl : 12, xl : 12, lg : 14, span : 24 },
        right : { xxl : 12, xl : 12, lg : 10, span : 24 }
    };

    if(loading){
        return <Loader type={ "block" } />;
    }

    return(
        <Row className="page-content" gutter={[ 24, 24 ]}>
            <Col {...span.left}>
                <Exhibition.Forms.EditHall
                    eeId     = { Number(eeId) }
                    isCreate = { isCreate  }
                    hall     = { hall }
                />
            </Col>
            { !isCreate &&
                <Col {...span.right }>
                    <Exhibition.Fields.HallPlanFile
                        withLabel = { true }
                        hall      = { hall }
                    />
                </Col>
            }
        </Row>
    );
};

export default ExhibitionHallSubPage;