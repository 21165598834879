import React, { JSX, useState } from "react";

import { Button } from "antd";

import { cloneChildren } from "../../../common/utils";
import Icons from "../icons";
import { DrawerStandard } from "components/service";
import Localize from "components/service/Localize";


interface FilterDrawerProps {
    btnClass     ?: string
    filtersCount ?: number
    children     ?: React.ReactNode
}

const FiltersDrawer : React.FC<FilterDrawerProps> = (
    {
        btnClass = 'inverted',
        children,
        filtersCount = 0
    }): JSX.Element => {


    const [ openDrawer, setOpenDrawer ] = useState( false );

    return (
        <>
            <Button
                type      = "default"
                className = { btnClass }
                onClick   = { () => setOpenDrawer(true) }
            >
                <Icons.Filter />
                <Localize wrap count={ filtersCount }>GLOBAL.Button_Label_Filters</Localize>
            </Button>
            <DrawerStandard
                title      = {
                    <Localize
                        wrap tag = "h2"
                        count    = { filtersCount }
                    >
                        GLOBAL.Modal_Title_Filters
                    </Localize>
                }
                isOpen     = { openDrawer }
                extraClass = { "select-modal" }
                close      = { () => setOpenDrawer(false) }
            >
                { cloneChildren(
                    children as React.ReactElement,
                    {
                        callBack : () => setOpenDrawer( false )
                    }
                )}
            </DrawerStandard>
        </>

    );
};

export default FiltersDrawer;