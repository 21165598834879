import React, { JSX, useState } from "react";

import { useQuery } from "@apollo/client";
import { Modal, Radio } from "antd";

import { GET_TEAM_USERS } from "graphql/query/user-q.gql";

import { whereConditionBuilder } from "common/utils";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";

import type { ETeamRoles, OutsideControlledModal } from "common/types";


interface ITeamMemberModalProps extends OutsideControlledModal{
    teamId        ?: number | undefined
    teamRole      ?: ETeamRoles | undefined
    currentMember ?: number
    modalTitle     : string
}

type TMember = {
    id : number
    name: string
    surname: string
};

const TeamMemberSelectModal : React.FC<ITeamMemberModalProps> = ({
        isOpen,
        close,
        teamId,
        teamRole,
        currentMember,
        modalTitle,
        confirm
    }) : JSX.Element => {

    const [ memberId, setMemberId ] = useState(currentMember);

    const { data, loading } = useQuery( GET_TEAM_USERS, {
        variables : {
            whereTeamsUser : whereConditionBuilder({
                teamRole : !teamRole ? {} : {
                    column : "TEAM_ROLE",
                    operator : "EQ",
                    value : teamRole
                },
                teamId : !teamId ? {} : {
                    column : "TEAM_ID",
                    operator : "EQ",
                    value : teamId
                },
            })
        },
        fetchPolicy : "cache-first",
        skip : !isOpen
    });

    const {
        usersCursor : {
            edges : teamUsers = []
        } = {}
    } = data || {};

    return(
        <Modal
            title={
                <h2>{ modalTitle }</h2>
            }
            closeIcon={ <Icons.Cross /> }
            okText={ "Choose" }
            destroyOnClose={ true }
            className="select-modal radio"
            open={ isOpen }
            onOk={ () => {
                if(memberId){
                    const member = teamUsers.find( ({ node } : { node : TMember }) => node.id === memberId );

                    if(member){

                        const { node } = member;

                        confirm({
                            value : {
                                label : `${node.name} ${ node.surname }` ,
                                value : node.id
                            }
                        });
                    }
                }

                close();
            } }
            onCancel={ () => { close(); } }
            centered={ true }
        >
            <div className="radio-select-wrap" >
                { loading ?
                    <Loader /> :
                    <Radio.Group
                        onChange={ (e) => setMemberId(e.target.value) }
                        value={ memberId }
                    >
                        { teamUsers.map( (
                            { node : { id, name, surname } } :
                                { node : TMember }
                        ) => {

                            return(
                                <Radio key={ id } value={ id }>
                                    { name } { surname }
                                </Radio>
                            );
                        } )}

                    </Radio.Group>
                }

            </div>

        </Modal>
    );
};

export default TeamMemberSelectModal;