import React, { JSX, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Form, FormInstance, InputNumber } from "antd";

import { STAND_SIZE_TYPE_UPDATE } from "graphql/mutation/stand-m.gql";

import StandFields from "../fields";
import Localize, { localizeByKey } from "components/service/Localize";
import { useNotification } from "components/use-hook";

import type { TStandTypeProps } from "../fields/Stand-type-select";
import type { TID } from "graphql/type/common-gql-types";


interface IStandCadStatusFormProps {
    standTypeInfo : {
        groupId       : TID
        title         : string
        standId       : TID
        eeOrganizerId : TID
        eeTypeId      : TID
    }
    size : {
        width  : number
        height : number
        min    : number
        max    : number
    }
    setForm   ?: (form :  FormInstance) => void
    onUpdate  ?: (callback ?: () => void) => void
}

const StandChangeTypeSizeForm : React.FC<IStandCadStatusFormProps> = (
    {
        standTypeInfo,
        size,
        setForm,
        onUpdate = () => {}
    }) : JSX.Element => {
    const { nError, nSuccess } = useNotification();

    const [ standChangeTypeOrSize, { loading } ] = useMutation(
        STAND_SIZE_TYPE_UPDATE,
        {
            update(cache, { data }) {

                const { 'standChangeTypeOrSize' : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);
                onUpdate();
            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const [ form ] = Form.useForm();

    useEffect(() => {
        if(setForm){
            setForm(form);
        }
    }, []);

    const standType : TStandTypeProps = Form.useWatch<string, FormInstance>('standType', form),
        width : number = Number(Form.useWatch<string, FormInstance>('size_width', form)) || 0,
        height : number = Number(Form.useWatch<string, FormInstance>('size_height', form)) || 0;

    useEffect( () => {
        form.setFieldValue('stand_area', width * height);
    }, [ width, height ] );

    return(
        <div className = "stand-change-type-size-form">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues = {{
                    size_width : size.width || 0,
                    size_height : size.height || 0,
                    standType : {
                        value : standTypeInfo.groupId,
                        label : standTypeInfo.title
                    },
                }}
                onValuesChange = {
                    (changedValues) => {
                        const {
                            size_width = size.width,
                            size_height = size.height
                        } = changedValues;

                        if ( changedValues.size_height || changedValues.size_width) {
                            form.setFieldValue('stand_area', size_width * size_height);
                        }
                    }
                }
                onFinish={(values) => {
                    if (!loading) {
                        standChangeTypeOrSize({
                            variables : {
                                input : {
                                    stand_id : standTypeInfo.standId,
                                    stand_type_group : values.standType.value,
                                    size_width : values.size_width,
                                    size_height : values.size_height
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label = {
                        <Localize>STAND.Field_Label_StandType</Localize>
                    }
                    name  = "standType"
                    rules = {[
                        {
                            required : true,
                            message : "required"
                        }
                    ]}
                >
                    <StandFields.TypeSelect
                        orgId    = { Number(standTypeInfo.eeOrganizerId) }
                        etId     = { Number(standTypeInfo.eeTypeId) }
                        disabled = { false }
                    />
                </Form.Item>
                <div>
                    <Localize
                        wrap tag  = "h5"
                        className = "label"
                        minSize   = { standType?.min || size.min }
                        maxSize   = { standType?.max || size.max }
                    >
                        STAND.Field_Label_Size
                    </Localize>
                    <div className = "grid-three-col">
                        <Form.Item
                            name  = "size_width"
                            rules = {[
                                {
                                    type : "number",
                                    min : 0
                                }
                            ]}
                        >
                            <InputNumber name       = "stand-width"
                                         addonAfter = { localizeByKey('GLOBAL.Field_Postfix_M') }
                            />
                        </Form.Item>
                        <Form.Item
                            name  = "size_height"
                            rules = {[
                                {
                                    type : "number",
                                    min : 0
                                }
                            ]}
                        >
                            <InputNumber name       = "stand-height"
                                         addonAfter = { localizeByKey('GLOBAL.Field_Postfix_M') }
                            />
                        </Form.Item>
                        <Form.Item
                            name  = "stand_area"
                            rules = {[
                                {
                                    type : "number",
                                    min : standType?.min || size.min,
                                    max : standType?.max || size.max,
                                    message :
                                        <Localize
                                            sizeMin   = { standType?.min || size.min }
                                            sizeMax   = { standType?.max || size.max }
                                        >
                                            STAND.FieldError_Text_AreaBetween
                                        </Localize>
                                },
                            ]}
                        >
                            <InputNumber disabled
                                         name       = "stand-area"
                                         addonAfter = { localizeByKey('GLOBAL.Field_Postfix_SQM') }
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default StandChangeTypeSizeForm;