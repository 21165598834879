import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const InformationSvg : React.FC = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M32.5,38.9h-6.1V17.5h6.1c1.1,0,2-0.9,2-2s-0.9-2-2-2H16.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h6.1v21.4h-6.1c-1.1,0-2,0.9-2,2
		s0.9,2,2,2h16.2c1.1,0,2-0.9,2-2S33.6,38.9,32.5,38.9z"/>
            <path d="M24.3,9.8c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5C21.8,8.7,22.9,9.8,24.3,9.8z" />
        </g>
    </svg>
);

const InformationSquaredSvg : React.FC = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M27.9,22.1c1.1,0,2-.9,2-2s-.9-2-2-2h-7.9c-1.1,0-2,.9-2,2s.9,2,2,2h2v8.5h-2c-1.1,0-2,.9-2,2s.9,2,2,2h7.9c1.1,0,2-.9,2-2s-.9-2-2-2h-2v-8.5h2Z"/>
            <circle  cx="23.9" cy="13.9" r="2.3"/>
            <path d="M38.3,3.3H9.5c-3.2,0-5.9,2.6-5.9,5.9v28.8c0,3.2,2.6,5.9,5.9,5.9h28.8c3.2,0,5.9-2.6,5.9-5.9V9.2c0-3.2-2.6-5.9-5.9-5.9ZM40.2,38c0,1-.8,1.9-1.9,1.9H9.5c-1,0-1.9-.8-1.9-1.9V9.2c0-1,.8-1.9,1.9-1.9h28.8c1,0,1.9.8,1.9,1.9v28.8Z"/>
        </g>
    </svg>
);

const InformationIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.type === 'squared') {
        return <Icon component={ InformationSquaredSvg } />;
    }

    return <Icon component={ InformationSvg } />;
};


export default InformationIcon;
