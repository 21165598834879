import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';
 
import { GET_EE_HALLS } from "graphql/query/exhibition-q.gql";

import ExhibitionHelpers from "../helpers";
import { Loader } from "components/request-result";

import type { TFilterFields } from "common/types";

//import {ColumnsType} from "antd/es/table";


type TExhibitionHallsTableProps = {
    filters : TFilterFields
    eeId    : number
};

const ExhibitionHallsTable : React.FC<TExhibitionHallsTableProps> = ({ filters, eeId }) : JSX.Element  => {

    const { data : { halls } = { },
        loading,
        // error
    } = useQuery( GET_EE_HALLS, {
        variables : {
            text : filters.text.length ? filters.text : undefined,
            where : {
                column : "EE_ID",
                operator : "EQ",
                value : eeId
            },
            orderBy : [ { column : "ID", order : "ASC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { columns, data } = ExhibitionHelpers.hallTable;


    return(
        <div className="exhibition-halls-table-wrap">
            { !!halls &&
                <Table
                    className  = "exhibition-halls-table"
                    columns    = { columns! }
                    dataSource = { data( halls.data, eeId )! }
                    pagination = { false }
                />
            }
            { loading && <Loader type={ "block" } /> }
        </div>
    );
};

export default ExhibitionHallsTable;