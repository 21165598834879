import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { subFieldCount } from "common/utils";
import { Blocks, Buttons, Fields, Modals } from "components/layout";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";
import Task from "components/task";
import type { TTaskFilters } from "components/task/task-types";


const TaskListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TTaskFilters>({ text : "" });

    return(
        <div className="page task-list-page">
            <Blocks.PageHeader
                title={
                    <Localize exhibitionName={ ee.title }>
                        TASK.List_PageTitle
                    </Localize>
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters    = { filters }
                            setFilters = { setFilters }
                        />
                        <Modals.Filters
                            filtersCount = { subFieldCount(filters) }
                        >
                            <Task.Forms.ListFilter
                                ee         = { ee }
                                filters    = { filters }
                                setFilters = { setFilters }
                            />
                        </Modals.Filters>
                    </>}
                />
                <Task.Tables.All
                    eeId    = { ee.id }
                    filters = { filters }
                />
            </div>
        </div>
    );

};

export default TaskListPage;