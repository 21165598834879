import GraphicsListFilterTypesSelect from "./Graphics-list-filter-type-select";
import GraphicsProductTypeSelect from "./Graphics-product-type-select";
import GraphicsSupplierSelect from "./Graphics-supplier-select";


const GraphicsFields = {
    ProductTypeSelect : GraphicsProductTypeSelect,
    SupplierSelect : GraphicsSupplierSelect,
    FilterTypeSelect : GraphicsListFilterTypesSelect,
};

export default GraphicsFields;