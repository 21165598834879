import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { subFieldCount } from "common/utils";
import { Blocks, Buttons, Fields, Modals } from "components/layout";
import { ROUTES } from "components/routes";
import { LinkWithIcon } from "components/service";
import Localize from "components/service/Localize";
import Stand from "components/stand";
import type { TStandFilters } from "components/stand/stand-types";


const StandListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TStandFilters>({ text : "" });

    return(
        <div className="page stand-list-page">
            <Blocks.PageHeader
                title={
                    <Localize exhibitionName={ ee.title }>
                        STAND.List_PageTitle
                    </Localize>
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters    = { filters }
                            setFilters = { setFilters }
                        />
                        <Modals.Filters
                            filtersCount = { subFieldCount(filters) }
                        >
                            <Stand.Forms.ListFilter
                                ee         = { ee }
                                filters    = { filters }
                                setFilters = { setFilters }
                            />
                        </Modals.Filters>
                    </>}
                    actions = {<>
                        <LinkWithIcon
                            route={ "STAND_ITEM_CREATE_FULL" }
                            routeParams={ { 'eeId' : ee.id } }
                        >
                            <Localize>
                                STAND.Button_Label_AddStand
                            </Localize>
                        </LinkWithIcon>
                    </>}
                />
                <Stand.Tables.All
                    eeId    = { ee.id }
                    filters = { JSON.parse(JSON.stringify(filters)) }
                />
            </div>
        </div>
    );
};

export default StandListPage;