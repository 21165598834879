import React, { JSX, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { subFieldCount } from "common/utils";
import Graphics from "components/graphics";
import type { TGraphicsFilters } from "components/graphics/graphics-types";
import { Blocks, Buttons, Fields, Modals } from "components/layout";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";


const GraphicListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();
    const [ filters, setFilters ] = useState<TGraphicsFilters>({ text : "" });

    return(
        <div className="page check-list-page">
            <Blocks.PageHeader
                title={
                    <Localize exhibitionName={ ee.title }>
                        GRAPHICS.List_PageTitle
                    </Localize>
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <div className="page-content-wrap">
                <Blocks.TableHeader
                    filters = {<>
                        <Fields.Search
                            filters={ filters }
                            setFilters={ setFilters }
                        />
                        <Modals.Filters
                            filtersCount = { subFieldCount(filters) }
                        >
                            <Graphics.Forms.ListFilter
                                eeId       = { ee.id }
                                filters    = { filters }
                                setFilters = { setFilters }
                            />
                        </Modals.Filters>
                    </>}
                />
                <Graphics.Tables.Orders
                    eeId        = { ee.id }
                    organizerId = { ee.organizer_id }
                    filters     = { filters }
                />
            </div>
        </div>
    );
};

export default GraphicListPage;