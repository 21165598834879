import {  useQuery } from "@apollo/client";

import { GET_EXHIBITION_TYPES } from "graphql/query/organizer-q.gql";

import { IUseGraphQLHook, IUseGraphQLHookProps } from "common/types";


interface IUseExhibitionTypesPayload extends IUseGraphQLHook{
    exhibitionTypesCursor : any | null
}

const useExhibitionTypes = (
    {
        fetchPolicy = "cache-only",
        withStatus  = false,
        skip = false,
    } : IUseGraphQLHookProps ) : IUseExhibitionTypesPayload  => {

    const {
        data : { exhibitionTypesCursor } = { },
        loading,
        error
    } = useQuery( GET_EXHIBITION_TYPES, {
        fetchPolicy,
        skip
    });

    return (withStatus) ?
        {
            exhibitionTypesCursor,
            loading,
            error
        } :
        { exhibitionTypesCursor };
};

export default useExhibitionTypes;