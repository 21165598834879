import React, { JSX } from "react";

import { Input } from "antd";

import TaskFields from "../fields";
import TaskHelpers from "../helpers";
import { ETaskFilterFieldTypes } from "../task-types";
import Exhibition from "components/exhibition";
import { Blocks } from "components/layout";
import { localizeByKey } from "components/service/Localize";
import Stand from "components/stand";
import Task from "components/task";
import Team from "components/team";
import type { TTeamMember } from "components/team/team-types";

import type { TTaskFilters } from "../task-types";
import { ETeamRoles } from "common/types";


type TStandListFilterProps = {
    ee          : any
    filters     : TTaskFilters,
    setFilters  : React.Dispatch<React.SetStateAction<TTaskFilters>>
    callBack   ?: () => void
};

const TaskListFilterForm : React.FC<TStandListFilterProps> = (
    {
        ee,
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    return (
        <Blocks.Filter.Form
            filterFields     = { TaskHelpers.filter.parse(filters) }
            fieldTypes       = { ETaskFilterFieldTypes }
            defaultFiledType = { ETaskFilterFieldTypes.NONE }
            translationKey   = { 'TASK.Select_Option_Filter' }
            onReset={
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onApply={
                (fields) => {
                    setFilters({
                        text : filters.text,
                        ...TaskHelpers.filter.build(fields)
                    });
                    callBack();
                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value, label }
                 }) => {

                    switch (type){
                        case ETaskFilterFieldTypes.COMPANY_NAME:
                        case ETaskFilterFieldTypes.STAND_NUMBER:
                        case ETaskFilterFieldTypes.CAD_NUMBER:
                        case ETaskFilterFieldTypes.BLOCK:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Input />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.HALL:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Exhibition.Fields.HallSelect
                                        eeId  = { ee.id }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.CAD_STATUS:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Stand.Fields.CadStatus
                                        status    = { 0 }
                                        noColor   = { true }
                                        showEmpty = { true }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.PROJECT_MANAGER:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Team.Fields.UserSelect
                                        modalTitle    = {
                                            localizeByKey('USER.Modal_Title_ChooseProjectManager')
                                        }
                                        teamId        = { ee.team_id }
                                        teamRole      = { ETeamRoles.PROJECT_MANAGER }
                                        currentMember = {
                                            value && label ?
                                                {
                                                    id : Number(value),
                                                    name : label.split(' ')[0],
                                                    surname : label.split(' ')[1]
                                                } as TTeamMember : undefined
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.AUTOCAD_ENGINEER:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Team.Fields.UserSelect
                                        modalTitle    = {
                                            localizeByKey('USER.Modal_Title_ChooseAutocadEngineer')
                                        }
                                        teamId        = { ee.team_id }
                                        teamRole      = { ETeamRoles.DRAFTSMEN }
                                        currentMember = {
                                            value && label ?
                                                {
                                                    id : Number(value),
                                                    name : label.split(' ')[0],
                                                    surname : label.split(' ')[1]
                                                } as TTeamMember : undefined
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.TASK_TYPE:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Task.Fields.Type
                                        disabled={ false }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.TASK_IMPORTANCE:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Task.Fields.Importance />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.TASK_STATUS:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Task.Fields.Status
                                        noColor   = { true }
                                        showEmpty = { true }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.TASK_ASSIGNED_TO:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes> { ...fieldSettings }>
                                    <Team.Fields.UserSelect
                                        modalTitle    = {
                                            localizeByKey('USER.Modal_Title_ChooseAutocadEngineer')
                                        }
                                        teamId        = { ee.team_id }
                                        teamRole      = { ETeamRoles.DRAFTSMEN }
                                        currentMember = {
                                            value && label ?
                                                {
                                                    id : Number(value),
                                                    name : label.split(' ')[0],
                                                    surname : label.split(' ')[1]
                                                } as TTeamMember : undefined
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case ETaskFilterFieldTypes.NONE:
                        default:
                            return (
                                <Blocks.Filter.Item<ETaskFilterFieldTypes>
                                    { ...fieldSettings }
                                    noValue = { true }
                                >
                                    <TaskFields.FilterTypeSelect
                                        filteredTypes = { filteredTypes as ETaskFilterFieldTypes[]}
                                        defaultValue  = { value && value.length ? value as ETaskFilterFieldTypes : type! }
                                    />
                                </Blocks.Filter.Item>
                            );
                    }
                }}
        </Blocks.Filter.Form>
    );
};

export default TaskListFilterForm;