import React, { JSX, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Form, FormInstance, Input } from "antd";

import {
    GRAPHICS_PRINT_DATA_CREATE,
    GRAPHICS_PRINT_DATA_UPDATE
} from "graphql/mutation/graphics-m.gql";

import Localize from "../../service/Localize";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";

interface IGraphicsPrintDataLinkEditFormProps {
    orderId      : TID
    linkData    ?: TGraphicsPrintData
    setForm     ?: (form :  FormInstance) => void
    onUpdate    ?: (data : TGraphicsPrintData) => void
}


const GraphicsPrintDataLinkEditForm : React.FC<IGraphicsPrintDataLinkEditFormProps> = (
    {
        orderId,
        linkData,
        setForm,
        onUpdate = () => {}
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ graphicsPrintDataLinkEdit, { loading } ] = useMutation(
        linkData ? GRAPHICS_PRINT_DATA_UPDATE  : GRAPHICS_PRINT_DATA_CREATE,
        {
            update(cache, { data }) {

                const { [linkData ? 'graphicsPrintDataUpdate' : 'graphicsPrintDataCreate' ] : {
                    label,
                    message,
                    graphicsPrintData
                }  } = data;

                nSuccess(label, message);
                onUpdate(graphicsPrintData);
            },
            onError(error) {
                console.error(error);
            },
        }
    );

    const [ form ] = Form.useForm();

    useEffect(() => {
        if(setForm){
            setForm(form);
        }
    }, []);

    return(
        <div className="graphics-order-create-form">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues = {
                    linkData ? {
                        title : linkData.title,
                        description : linkData.description
                    } : {}
                }
                onFinish={(values) => {
                    if(!loading){
                        graphicsPrintDataLinkEdit({
                            variables : {
                                input : {
                                    ...values,
                                    ...( linkData && { id : linkData.id } ),
                                    graphics_order_id : orderId,
                                    type : 'link',
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                <Form.Item
                    label = { <Localize>GRAPHICS.Field_Label_LinkTitle</Localize> }
                    name  = "title"
                    rules = {[ {
                        required : true,
                        message : "required"
                    } ]}
                >
                    <Input name="link-title"/>
                </Form.Item>
                <Form.Item
                    label = { <Localize>GRAPHICS.Field_Label_LinkUrl</Localize> }
                    name  = "description"
                    rules = {[ {
                        type : 'url',
                        required : true,
                        message : "required"
                    } ]}
                >
                    <Input name="link-url"/>
                </Form.Item>
            </Form>
        </div>
    );
};

export default GraphicsPrintDataLinkEditForm;