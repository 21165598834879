import React from "react";

import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { TASK_CREATE } from "graphql/mutation/task-m.gql";

import { Icons } from "components/layout";
import { useNotification } from "components/use-hook";

import type { ButtonType } from "antd/es/button/buttonHelpers";
import type { TID } from "graphql/type/common-gql-types";
import type { TTask } from "graphql/type/task-gql-types";


interface ITaskEditButtonProps {
    standId      : TID
    btnType      : ButtonType
    isCreate     : boolean
    setTaskState : (task : TTask) => void
    setOpenModal : (open : boolean) => void
}

const TaskEditButton : React.FC<ITaskEditButtonProps> = ({
        btnType,
        standId,
        setOpenModal,
        setTaskState,
        isCreate
    }) => {

    const { nError, nSuccess } = useNotification();

    const [ taskCreate, { loading } ] = useMutation(
        TASK_CREATE,
        {
            update(cache, { data }){
                const { "taskCreate" : {
                    label,
                    message,
                    task
                }  } = data;

                setTaskState(task);

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    return(
        <Button
            type    = { btnType }
            onClick = { () => {
                if(!loading){
                    if(isCreate){
                        taskCreate({
                            variables : {
                                standId
                            }
                        }).then( () => setOpenModal(true) )
                            .catch(nError);
                    } else {
                        setOpenModal(true);
                    }
                }

            }}
        >
            { !isCreate ? <Icons.Edit /> : <Icons.Plus loading={ loading } /> }
            { !isCreate ? 'Edit Task' : 'Add Task' }
        </Button>
    );
};

export default TaskEditButton;