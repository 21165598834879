import React, { JSX, useState } from "react";

import { Button, Form, FormItemProps, Select } from "antd";

import StandBlocks from "../blocks";
import StandLists from "../lists";
import { Icons } from "components/layout";
import { DrawerStandard } from "components/service";

import type { IStandTypeSelect } from "../stand-types";
import type { NamePath } from "rc-field-form/es/interface";


type TStandTypeSelectProps = {
    etId             ?: number | undefined
    orgId            ?: number | undefined
    currentStandType ?: IStandTypeSelect | undefined
    disabled          : boolean
};

export type TStandTypeProps = {
    label         : string,
    value         : string,
    standTypeId   : string,
    min           : number,
    max           : number,
    orientations  : any[]
};

const StandTypeSelect : React.FC<TStandTypeSelectProps> = ({
        etId = undefined,
        orgId = undefined,
        currentStandType,
        disabled,
        ...props
    }) : JSX.Element => {

    const form = Form.useFormInstance(),
        [ openDrawer, setOpenDrawer ] = useState( false ),
        [ standType, setStandType ] = useState<IStandTypeSelect | undefined>( currentStandType );

    const confirmSelect = (options ?: IStandTypeSelect  ) => {

        if(options){

            const { id } = props as FormItemProps;

            form.setFieldValue(
                id! as NamePath,
                {
                    label : `${ options.title }`,
                    value : options.group_id,
                    orientations : options.orientations,
                    standTypeId : options.id,
                    min : options.size_min,
                    max : options.size_max,
                }
            );
        }

        setOpenDrawer(false);

    };

    return(
        <>
            <Select
                { ...props }
                defaultValue  = { ""! }
                onClick       = { () => !disabled && setOpenDrawer(true) }
                dropdownStyle = { { display : "none" } }
                suffixIcon    = { <Icons.Arrow /> }
                disabled      = { disabled }
                options={ [
                    { value : '', label : 'Not selected' }
                ]! }
            />
            <DrawerStandard
                title      = { <h2>Select stand type</h2> }
                isOpen     = { openDrawer }
                extraClass = { "select-modal radio" }
                close      = { () => setOpenDrawer(false) }

                extra={
                    <div className="form-actions">
                        <Button onClick={
                            () => setOpenDrawer(false)  }
                        >
                            Cancel
                        </Button>
                        <Button type="primary"
                                onClick={ () =>  confirmSelect( standType ) }
                        >
                            Choose
                        </Button>
                    </div>
                }
            >
                <StandBlocks.TypeFilter
                    filterProps = {{
                        etId,
                        orgId
                    }}
                >
                    { (filteredProps) => (
                        <StandLists.TypeRadio
                            standType    = { standType }
                            setStandType = { setStandType }
                            { ...filteredProps}
                        />
                    ) }

                </StandBlocks.TypeFilter>
            </DrawerStandard>
        </>
    );
};

export default React.memo(StandTypeSelect);