import React, { JSX, useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { STAND_COMMENT_DELETE } from "graphql/mutation/stand-m.gql";
import { GET_STAND_COMMENTS } from "graphql/query/stand-q.gql";

import StandHelpers from "../helpers";
import StandModals from "../modals";
import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";
import { Blocks, Lists, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { DeleteButton } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";

import { IMessageItem } from "common/types";
import type { TID } from "graphql/type/common-gql-types";
import type { TStandComment } from "graphql/type/stand-gql-types";
import type { TUser } from "graphql/type/user-gql-types";


interface IStandCommentsBlockProps {
    standId : TID
    totalComments : string | number
}

const StandCommentsBlock : React.FC<IStandCommentsBlockProps> = ({ standId, totalComments }) : JSX.Element => {

    const [ standComments, setStandComments ] = useState<TStandComment[]>([]);

    const me : TUser = reactiveVarHelper().get('me');

    const { data : { standCommentsCursor } = { },
        loading,
    } = useQuery( GET_STAND_COMMENTS, {
        variables : {
            where : whereConditionBuilder({
                type : {
                    column : "TYPE",
                    operator : "EQ",
                    value : 'details'
                } ,
                standId : ! standId ? {} : {
                    column : "STAND_ID",
                    operator : "EQ",
                    value : standId
                },
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        skip : !totalComments,
        fetchPolicy : "cache-and-network"
    });


    useEffect(() => {
        setStandComments(standCommentsCursor?.edges.map(
            ( { node } ) => {
                return { ...node };
            }
        ) || []);
    }, [ standCommentsCursor ]);

    return(
        <Blocks.Card
            Header    = { <Localize>STAND.Block_Title_Comments</Localize> }
            Action = {
                <StandModals.CommentEdit
                    standId  = { standId }
                    type     = { "details" }
                    onUpdate = {
                        ( id, comment ) => {
                            setStandComments([ comment, ...standComments ]);
                        }
                    }
                />
            }
        >
            {!standComments.length && !loading &&
                <div className="no-data">
                    <Localize
                        wrap tag   = "p"
                        className  = "label"
                    >
                        STAND.NoData_Text_Comments
                    </Localize>
                </div>
            }
            {!loading && standComments.length > 0 &&
                <Lists.Messages
                    className  = "stand-equipment-comment"
                    messages   = {
                        StandHelpers.commentMap( standComments )
                    }
                    actionMenu = {
                        ( comment : IMessageItem ) : JSX.Element | null => {
                            if(me.id !== comment.user.id){
                                return null;
                            }

                            return(
                                <Menus.Popover>
                                    <StandModals.CommentEdit
                                        standId  = { standId }
                                        type     = { "details" }
                                        comment  = { standComments.find(
                                            (item) => comment.id === item.id)
                                        }
                                        btnClass = {''}
                                        onUpdate = {
                                            ( _, comment ) => {
                                                setStandComments(
                                                    standComments.map(
                                                        (item) => item.id === comment.id ? comment : item
                                                    )
                                                );
                                            }
                                        }
                                    />
                                    <DeleteButton
                                        className  = { "stand-comment-delete" }
                                        buttonType = { "text" }
                                        id         = { Number(comment.id) }
                                        query      = { STAND_COMMENT_DELETE }
                                        onSuccess  = {() => {
                                            setStandComments(
                                                standComments.filter(
                                                    (item) => item.id !== comment.id
                                                )
                                            );
                                        }}
                                        confirmText = { localizeByKey('STAND.Modal_Text_DeleteComment') }
                                    >
                                        <Localize wrap>GLOBAL.Action_Menu_Delete</Localize>
                                    </DeleteButton>
                                </Menus.Popover>
                            );

                        }
                    }
                />
            }
            { loading && <Loader type="block" /> }
        </Blocks.Card>
    );
};

export default StandCommentsBlock;