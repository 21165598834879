import React, { JSX } from "react";

import { Link } from "react-router-dom";


interface IPage404Props{
    goLink ?: string
    goText ?: string
}


const Page404 : React.FC<IPage404Props> = ({
        goLink = "/",
        goText = "Go on main"
    }) : JSX.Element => {

    return(
        <div className="page page-404">
            <h1>404</h1>
            <p>Sorry, that page doesn't exist</p>
            <Link to={ goLink! }>
                { goText }
            </Link>
        </div>
    );
};

export default Page404;