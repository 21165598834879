import exhibitionFilterHelper from "./exhibition-filter-helper";
import { prepareEditFormDataHelper, prepareEditInitDataHelper } from "./exhibition-form-edit-helper";
import ExhibitionHallsTableHelper from "./exhibition-hall-helper";
import ExhibitionsTableHelper from "./exhibitions-table-helper";


const ExhibitionHelpers = {
    editForm : {
        prepareData : prepareEditFormDataHelper,
        initialData : prepareEditInitDataHelper
    },
    filter : exhibitionFilterHelper,
    table : ExhibitionsTableHelper,
    hallTable : ExhibitionHallsTableHelper
};

export default ExhibitionHelpers;
