import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const TaskListSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path style = { { opacity : 0.5 } } d="M38.11,5.33H7.67c-1.48,0-2.69,1.2-2.69,2.69v30.44c0,1.49,1.2,2.69,2.69,2.69h30.44c1.48,0,2.69-1.2,2.69-2.69V8.01c0-1.49-1.2-2.69-2.69-2.69ZM18.22,33.59h-5.86c-1.21,0-2.19-.99-2.19-2.19s.99-2.19,2.19-2.19h5.86c1.21,0,2.19.99,2.19,2.19s-.99,2.19-2.19,2.19ZM18.64,25.53h-6.28c-1.21,0-2.19-.99-2.19-2.19s.99-2.19,2.19-2.19h6.28c1.21,0,2.19.99,2.19,2.19s-.99,2.19-2.19,2.19ZM18.97,17.28h-6.61c-1.21,0-2.19-.99-2.19-2.19s.99-2.19,2.19-2.19h6.61c1.21,0,2.19.99,2.19,2.19s-.99,2.19-2.19,2.19ZM32.99,17.28h-6.61c-1.21,0-2.19-.99-2.19-2.19s.99-2.19,2.19-2.19h6.61c1.21,0,2.19.99,2.19,2.19s-.99,2.19-2.19,2.19Z"/>
        <path d="M44.74,39.25l-5.37,4.93c-.49.45-1.29.4-1.77-.13l-12.9-14.05c-.25-.27-.4-.62-.43-.98l-1.34-7.28c-.04-.53.41-.93.93-.84l7.07,2.01c.35.06.68.24.92.51l12.91,14.06c.48.53.47,1.32-.02,1.78Z"/>
    </svg>
);


const TaskListIcon : FunctionComponent<IIcon> = ({ className }) : JSX.Element => {

    return <Icon component={ TaskListSvg } className={ className } />;

};

export default TaskListIcon;