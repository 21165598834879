import { useMutation } from "@apollo/client";

import { EQUIPMENT_ORDER_CREATE, EQUIPMENT_ORDER_SUBSTITUTE } from "graphql/mutation/equipment-m.gql";


import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";
import type { TEquipmentOrderType } from "graphql/type/equipment-gql-types";


interface IEquipmentHookVariables{
    variables : {
        orderId          ?: TID,
        equipmentGroupId  : TID,
        standId           : TID,
        type              : TEquipmentOrderType
    }
}

interface IUseEquipmentHook {
    isSubstitute  : boolean
    onUpdate     ?:  () => void
}

const useEquipmentAddChangeHook = ({
        isSubstitute,
        onUpdate,
    } : IUseEquipmentHook) => {

    const { nSuccess, nError } = useNotification();

    const [ equipmentOrderCreate, { loading : createLoading } ] = useMutation(
        EQUIPMENT_ORDER_CREATE,
        {
            update(cache, { data }) {

                if(data){
                    const { standEquipmentOrderCreate : {
                        label,
                        message,
                    }  } = data;

                    if(onUpdate) onUpdate();

                    nSuccess(label, message);
                }

            },
            refetchQueries : [ 'GetStandEquipmentOrders' ],
            onError(error) {
                console.error(error);
            }
        }
    );


    const [ equipmentOrderChange, { loading : changeLoading } ] = useMutation(
         EQUIPMENT_ORDER_SUBSTITUTE,
        {
            update(_, { data }) {

                if(data) {
                    const {
                        standEquipmentOrderChangeSubstitute : {
                            label,
                            message,
                        }
                    } = data;


                    if (onUpdate) onUpdate();

                    nSuccess(label, message);
                }
            },
            onError(error) {
                console.error(error);
            }
        }
    );

    return {
        equipmentAction : ({ variables }: IEquipmentHookVariables) => {
            if(isSubstitute && variables.orderId) {
                equipmentOrderChange({
                    variables : {
                        orderId : variables.orderId,
                        equipmentGroupId : variables.equipmentGroupId
                    }
                }).catch(nError);
            }

            if(!isSubstitute) {
                equipmentOrderCreate({
                    variables : {
                        input : {
                            equipment_group : variables.equipmentGroupId,
                            stand_id : variables.standId,
                            set_type : variables.type
                        }    
                    }
                }).catch(nError);
            }
        },
        loading : isSubstitute ? changeLoading : createLoading

    };
};

export default useEquipmentAddChangeHook;