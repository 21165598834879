import React, { JSX } from "react";

import { Select } from "antd";

import StandHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";

import type { TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface ICadStatusSelectProps {
    status    ?: string | number
    noColor   ?: boolean
    showEmpty ?: boolean
    value     ?: any
}

const StandCadStatusSelect : React.FC<ICadStatusSelectProps> = (
    {
        status,
        noColor  = false,
        showEmpty = false,
        ...props
    }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const cadVariables = variableLists.filter(
                                        (listItem :  TVariableItem) => listItem.group_name === 'cad status'
                                    ) ;

    const colorClass = !noColor ? `-${StandHelpers.cad.statusClass(status as string)}` : '';

    return(
        <Select
            { ...props }
            className     = { `select-status${ colorClass }` }
            defaultValue  = { status }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { (!cadVariables.length || showEmpty ) &&
                <Option key={ 0 } value={ "" }>
                    <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
                </Option>
            }
            { cadVariables.map(
                ({ id, value, slug }) =>
                    <Option key={ id } value={ slug }>{ value }</Option>
                )
            }
        </Select>
    );
};

export default React.memo(StandCadStatusSelect);