import { Dayjs } from "dayjs";

import { ECheckListType } from "../check-list-types";
import { getDate } from "common/utils";

import { EDateType } from "common/types";


type TCheckListGetDateReturn = {
    date : Dayjs | undefined
    formatType: EDateType
    format: string
    withTime : boolean
};

const checkListGetDateData = (value : any, type : ECheckListType) : TCheckListGetDateReturn  => {

    let dateValue = value === "" || value === null || value === undefined ? undefined : value;
    let formatType = EDateType.DATE;

    const withTime = type.indexOf('time') >= 0;

    switch(type){
        case ECheckListType.TIME:
            formatType  = EDateType.TIME;
            break;

        case ECheckListType.DAYDATE:
            formatType  = EDateType.DAYDATE;
            break;

        case ECheckListType.DAYDATETIME:
            formatType = EDateType.DAYDATETIME;
            break;

        case ECheckListType.DATETIME:
            formatType = EDateType.DATETIME;
            break;

        case ECheckListType.DATE:
        case ECheckListType.DATE_RANGE:
        case ECheckListType.DATE_AND_TIME:
        case ECheckListType.TEXT_DATE:
        default:
            formatType = EDateType.DATE;
    }

    if(dateValue){
        dateValue = getDate.fromStr(dateValue, formatType);
    }

    return {
        date : dateValue,
        formatType,
        withTime,
        format : getDate.format(formatType)
    };
};

export default checkListGetDateData;