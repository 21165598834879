import React from "react";

import { generatePath, Link } from "react-router-dom";

import { STAND_DELETE } from "graphql/mutation/stand-m.gql";

import { Icons, Menus } from "components/layout";
import { ROUTES } from "components/routes";
import { DeleteButton, LinkWithIcon, TagStandard } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";

import type { TNode } from "graphql/type/common-gql-types";
import type { TGraphicsOrder, TGraphicsSupplier } from "graphql/type/graphics-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ClientName
            </Localize>
        ,
        dataIndex : "companyName",
        columnIndex : "COMPANY_NAME",
        width : 260,
        fixed : 'left' as FixedType,
        //sorter: true,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ReadinessStatus
            </Localize>
        ,
        dataIndex : "status",
        columnIndex : "STATUS",
        width : 250,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ProductsQuantity
            </Localize>,
        dataIndex : "quantity",
        columnIndex : "Quantity",
        width : 120,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_MpStatus
            </Localize>,
        dataIndex : "mpStatus",
        columnIndex : "MP_STATUS",
        width : 120,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ProductType
            </Localize>,
        dataIndex : "productType",
        columnIndex : "PRODUCT_TYPE",
        width : 200,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_Material
            </Localize>,
        dataIndex : "material",
        columnIndex : "MATERIAL",
        width : 180,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_PrintingData
            </Localize>,
        dataIndex : "printingData",
        columnIndex : "PRINTING_DATA",
        width : 200,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_Color
            </Localize>,
        dataIndex : "color",
        columnIndex : "COLOR",
        width : 150,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_VisibleW
            </Localize>,
        dataIndex : "visibleWidth",
        columnIndex : "VISIBLE_WIDTH",
        width : 80,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_VisibleH
            </Localize>,
        dataIndex : "visibleHeight",
        columnIndex : "VISIBLE_HEIGHT",
        width : 80,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ActualW
            </Localize>,
        dataIndex : "actualWidth",
        columnIndex : "ACTUAL_WIDTH",
        width : 80,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ActualH
            </Localize>,
        dataIndex : "actualHeight",
        columnIndex : "ACTUAL_HEIGHT",
        width : 80,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_MontageType
            </Localize>,
        dataIndex : "montageType",
        columnIndex : "MONTAGE_TYPE",
        width : 180,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_MontageComment
            </Localize>,
        dataIndex : "montageComment",
        columnIndex : "MONTAGE_COMMENT",
        width : 250,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_OfferPrice
            </Localize>,
        dataIndex : "priceOffer",
        columnIndex : "PRICE_OFFER",
        width : 120,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_MpInternalOrder
            </Localize>,
        dataIndex : "mpInternalOrder",
        columnIndex : "MP_INTERNAL_ORDER",
        width : 120,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_NumberOfLetters
            </Localize>,
        dataIndex : "numberOfLetters",
        columnIndex : "NUMBER_OF_LETTERS",
        width : 100,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_EffectiveArea
            </Localize>,
        dataIndex : "effectiveArea",
        columnIndex : "EFFECTIVE AREA",
        width : 100,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_CalculatedArea
            </Localize>,
        dataIndex : "calculatedArea",
        columnIndex : "CALCULATED AREA",
        width : 100,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_GraphicCompanyOrderNumber
            </Localize>,
        dataIndex : "supplierOrder",
        columnIndex : "SUPPLIER_ORDER",
        width : 150,
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_ProductionPrice
            </Localize>,
        dataIndex : "priceProduction",
        columnIndex : "PRODUCTION_PRICE",
        width : 100,
        className : "price"
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_MontagePrice
            </Localize>,
        dataIndex : "priceMontage",
        columnIndex : "MONTAGE_PRICE",
        width : 100,
        className : "price"
    },
    {
        title :
            <Localize br={ <br /> }>
                GRAPHICS.TableColumn_Header_Total
            </Localize>,
        dataIndex : "total",
        columnIndex : "Total",
        width : 100,
        className : "total"
    },
    {
        dataIndex : "action",
        width : 60,
        fixed : 'right' as FixedType,
        className : "actions"

    }
];


const dataMap = ( graphicsOrders : TNode<TGraphicsOrder>[], graphicsSuppliers ?: TGraphicsSupplier[]) => {

    return graphicsOrders.map( ({ node  }) => {

        const {
            id,
            material,
            graphic_supplier_id,
            color,
            status,
            product_type,
            visible_width,
            visible_height,
            actual_width,
            actual_height,
            quantity,
            area_order,
            price_offer,
            area_paid,
            montage,
            montage_comment,
            mp_internal_order,
            relPrintData,
            supplier_order,
            price,
            price_montage,
            total,
            relStand,
        } = node;


        const graphicsSupplier = graphicsSuppliers?.find(
            ({ id }) => id === graphic_supplier_id
        );

        const rule = graphicsSupplier?.relRule.find(
            ({ id }) => id === product_type
        );

        const countPrintData = {
            files : 0,
            text : 0,
            total : 0
        };

        let letterAmount = undefined;

        relPrintData?.forEach( ( printData ) => {
            if([ 'link', 'file' ].includes( printData.type) ){
                countPrintData.files += 1;
            }

            if(printData.type === 'text'){
                countPrintData.text += 1;

                letterAmount = printData ?
                    printData.description
                        .replaceAll(' ', '')
                        .replaceAll('\n', '').length : 0;
            }
        });

        countPrintData.total = countPrintData.files + countPrintData.text;

        
        return{
            key : id,
            companyName :
                <>
                    <Link className="item-link"
                          to={
                            generatePath(
                                ROUTES.STAND_ITEM_GRAPHIC_FULL,
                                {
                                    eeId : Number(relStand.ee_id),
                                    standId : Number(relStand.id)
                                }
                            )
                          }
                          state       = {{ fromPage : 'graphics-list' }}
                    >
                        { relStand.company_name }
                    </Link>
                    <p className="stand-info">
                        { relStand.hall_title } / { relStand.stand_number } / { relStand.block }
                    </p>
                </>,
            status :
                <div className="order-status-wrap">
                    <TagStandard className={'new'} >
                        <Localize>{`VARIABLE_ITEM.${ status }`}</Localize>
                    </TagStandard>
                </div>,
            quantity : quantity || 0,
            mpStatus : <>—</>,
            productType : rule?.title || <>—</>,
            material : material ?
                <Localize>{`VARIABLE_ITEM.${ material }`}</Localize> : <>—</>,
            printingData : countPrintData.total ?
                <>
                    { !!countPrintData.files &&
                        <Localize count={ countPrintData.files } tag="b" wrap>
                            {countPrintData.files > 1 ? 'GLOBAL.Label_Text_Files' : 'GLOBAL.Label_Text_File'}
                        </Localize>
                    }
                    { !!countPrintData.text &&
                        <Localize count={ countPrintData.text } tag="b" wrap>
                            {countPrintData.text > 1 ? 'GLOBAL.Label_Text_Texts' : 'GLOBAL.Label_Text_Text'}
                        </Localize>
                    }
                </>
                : <>—</>,
            color : color ?
                <Localize>{`VARIABLE_ITEM.${ color }`}</Localize> : <>—</>,
            visibleWidth : visible_width ? visible_width : <>—</>,
            visibleHeight : visible_height ? visible_height : <>—</>,
            actualWidth : actual_width ? actual_width : <>—</>,
            actualHeight : actual_height ? actual_height : <>—</>,
            montageType : montage    ?
                <Localize>{`VARIABLE_ITEM.${ montage }`}</Localize> : <>—</>,
            montageComment :
                montage_comment ?
                    <p className="comment-wrap">
                        <Icons.Comment /> { montage_comment }
                    </p> : <>—</>,
            priceOffer : price_offer || <>—</>,
            mpInternalOrder : mp_internal_order || <>—</>,
            numberOfLetters : letterAmount || <>—</>,
            effectiveArea : area_order ? area_order : <>—</>,
            calculatedArea : area_paid ?  area_paid : <>—</>,
            supplierOrder : supplier_order ? supplier_order : <>—</>,
            priceProduction : price ? price : <>—</>,
            priceMontage : price_montage ? price_montage : <>—</>,
            total : total ? total : <>—</>,
            action :
                <Menus.Popover>
                    <LinkWithIcon
                        route       = { "STAND_ITEM_GRAPHIC_FULL" }
                        routeParams = {{
                            eeId : Number(relStand.ee_id),
                            standId : Number(relStand.id)
                        }}
                        state       = {{ fromPage : 'graphics-list' }}
                        icon        = { "Edit" }
                        className   = { "link-text" }
                    >
                        <Localize>
                            GLOBAL.Action_Menu_Edit
                        </Localize>
                    </LinkWithIcon>
                    <DeleteButton
                        className   = { "stand-delete" }
                        buttonType  = { "text" }
                        id          = { Number(0) }
                        query       = { STAND_DELETE }
                        reFetch     = {[ "GetStands" ]}
                        confirmText = {
                            localizeByKey('GRAPHICS.Modal_Text_DeleteGraphics')
                        }
                    >
                        <Localize>
                            GLOBAL.Action_Menu_Delete
                        </Localize>
                    </DeleteButton>
                </Menus.Popover>
        };

    });
};


const graphicsTableHelper = {
    columns,
    totalColumnWidth : () : number => {
        return columns.reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default graphicsTableHelper;