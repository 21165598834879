import React, { JSX } from "react";

import { Select } from "antd";

import { EExhibitionStatus } from "../exhibition-types";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";


const { Option } = Select;

type TExhibitionHallsProps = {
    value   ?: string
};

const ExhibitionStatusSelect : React.FC<TExhibitionHallsProps> = ({ ...props }) : JSX.Element => {

    return(
        <Select
            { ...props }
            defaultValue    = { ""! }
            suffixIcon      = { <Icons.Arrow /> }
            optionLabelProp = "children"
        >
            <Option key={ 0 } value={ "" }>
                <Localize>GLOBAL.Placeholder_Text_NotSelected</Localize>
            </Option>
            { Object.keys(EExhibitionStatus).map(
                (key) => {
                    return(
                        <Option key={ key } value={ key } >
                            <Localize>
                                { `EXHIBITION.Select_Option_Status_${EExhibitionStatus[ key as keyof typeof EExhibitionStatus ]}` }
                            </Localize>
                        </Option>
                    );
                }
            )}
        </Select>
    );
};

export default React.memo(ExhibitionStatusSelect);