import React, { JSX, useEffect } from "react";

import { Form, Select } from "antd";
import { NamePath } from "rc-field-form/es/interface";

import useExhibitionTypes from "../hooks/useExhibitionTypes";
import useOrganizerFragment from "../hooks/useOrganizerFragment";
import { Icons } from "components/layout";

import { EOrganizerOrETStatus } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


const { Option } = Select;

type TExhibitionTypeProps = {
    organizerId       : TID,
    exhibitionTypeId ?: TID,
    filterClosed     ?: boolean
    id               ?: string
};

const ExhibitionTypeSelect : React.FC<TExhibitionTypeProps> = (
    {
        organizerId,
        exhibitionTypeId,
        filterClosed  = true,
        ...props
    }) : JSX.Element => {

    const form = Form.useFormInstance();

    const organizer = useOrganizerFragment( { orgId : organizerId });

    const { exhibitionTypesCursor : {
        edges : exhibitionTypeNodes = []
    } = {} } = useExhibitionTypes(
        {
            withStatus : false,
            skip : !!organizerId,
            fetchPolicy : "cache-and-network"
    });

    let allTypes = [];

    if(exhibitionTypeNodes){
        allTypes = exhibitionTypeNodes.map( ({ node } : { node : object }) => node );
    }

    const {
        et_default_id : etDefault,
        exhibitionTypes = undefined
    } = organizer || {};

    useEffect(() => {
        if(typeof props.id === "string"){
            form.setFieldValue(
                props.id as NamePath,  exhibitionTypeId || etDefault
            );
        }
    }, [ organizer ]);


    return(
        <Select
            { ...props }
            defaultValue={ exhibitionTypeId || etDefault }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { (exhibitionTypes || allTypes).map(
                ({ id, title, status, organizer } : { id: number, title: string, status: EOrganizerOrETStatus, organizer : any } ) => {

                    if(filterClosed && status === EOrganizerOrETStatus.CLOSED){
                        return null;
                    }

                    const optionTitle = organizer ? `${organizer.title} - ${ title }` : title ;

                    return(
                        <Option key={ id } value={ id } >{ optionTitle }</Option>
                    );
                }
            )
            }
        </Select>
    );
};

export default ExhibitionTypeSelect;