import React, { JSX, useState } from "react";

import { gql, useApolloClient } from "@apollo/client";
import { Select } from "antd";

import { Icons } from "components/layout";
import { localizeByKey } from "components/service/Localize";

import { ETeamRoles } from "common/types";
 

type TFilterProps = {
    teamId : number | undefined
    teamRole : ETeamRoles | undefined
};

type TUserFilterProps = {
    readonly children  : ( props ?: any ) => JSX.Element
             filterProps : TFilterProps
};

const TeamUserFilterBlock : React.FC<TUserFilterProps> = ({ children, filterProps }) : JSX.Element => {

    const client = useApolloClient(),
          [ filterType,  setFilterType ] = useState(0);

    const team = client.readFragment({
        id : `Team:${ filterProps.teamId }`,
        fragment : gql`
            fragment TeamFields on Team {
                id
                title
            }
        `
    });

    const filterOptions = [];

    if(team){
        filterOptions.push(
            {
                value : 0,
                label : localizeByKey(
                    'USER.Select_Option_ShowJustTeamRole',
                    {
                        teamName : team.title,
                        teamRole : localizeByKey(
                            filterProps.teamRole === ETeamRoles.DRAFTSMEN ?
                                'USER.Team_Role_AutocadEngineers' :
                                'USER.Team_Role_Managers'
                        )
                    }
                ),
                filterFields : []
            },
            {
                value : 1,
                label : localizeByKey('' +
                    'USER.Select_Option_RoleFromAllTeams',
                    {
                        teamRole : localizeByKey(
                            filterProps.teamRole === ETeamRoles.DRAFTSMEN ?
                                'USER.Team_Role_AutocadEngineers' :
                                'USER.Team_Role_Managers'
                        )
                    }
                ),
                filterFields : [ "teamId" ]
            }
        );
    } else {
        filterOptions.push(
            {
                value : 0,
                label : localizeByKey('' +
                    'USER.Select_Option_RoleFromAllTeams',
                    {
                        teamRole : localizeByKey(
                            filterProps.teamRole === ETeamRoles.DRAFTSMEN ?
                                'USER.Team_Role_AutocadEngineers' :
                                'USER.Team_Role_Managers'
                        )
                    }
                ),
                filterFields : [ "teamId" ]
            }
        );
    }


    const filteredProps = { ...filterProps };

    filterOptions[filterType].filterFields.forEach( (item ) => {
            delete filteredProps[item as keyof TFilterProps];
        }
    );

    return(
        <>
            <div className="modal-filter">
                <Select
                    popupClassName = "modal-filter-dropdown"
                    suffixIcon  = { <Icons.Arrow /> }
                    onChange    = { (value) => setFilterType(value) }
                    value       = { filterType! }
                    options     = { filterOptions! }
                />
            </div>
            { children( filteredProps ) }
        </>
    );
};

export default TeamUserFilterBlock;