import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Collapse } from 'antd';

import { GET_CADS } from "graphql/query/stand-q.gql";

import Localize from "../../service/Localize";
import StandHelpers from "../helpers";
import { getDate } from "common/utils";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface IStandCadVersionProps {
    standId : TID
    currentCad : TStandCad
}

const StandCadVersionList : React.FC<IStandCadVersionProps> = ({ standId }) : JSX.Element => {

    const { data, loading } = useQuery( GET_CADS, {
        variables : {
            where : {
                column : "STAND_ID",
                operator : "EQ",
                value : standId
            },
            orderBy : [
                { column : "VERSION", order : "DESC" },
                { column : "CORRECTION", order : "DESC" }
            ]
        },
        fetchPolicy : "cache-first"
    });

    if(loading){
        return <Loader type="block" />;
    }

    const {
        cadsCursor : {
            edges : cadNodes = []
        } = {}
    } = data || {};


    return(
        <Collapse
            className  = "stand-cad-version-list"
            expandIcon = {
                () => <span className="plus-minus-toggle"/>
            }
            ghost
            accordion
            items={ 
                StandHelpers.cad.listMap(cadNodes).map(
                    ({ cad, versions }) => {
                    
                    return {
                        key : cad.version,
                        label :
                            <div className="cad-version">
                                <p>
                                    <Localize
                                        wrap
                                        className  = "version"
                                        cadVersion = { cad.version }
                                    >
                                        STAND.Text_CADVersion
                                    </Localize>
                                    <span className="number">
                                        | <Localize cadNumber={cad.cadNumber}>STAND.Text_CADNumber</Localize>
                                    </span>
                                </p>
                                <p className="count">{cad.count}</p>
                            </div>,
                        children :
                            <ul className="version-list">
                                {versions.map(
                                    (item) => {

                                        return (
                                            <li className = "version"
                                                key={ `${ item.id }-${ item.version }-${ item.correction }` }
                                            >
                                                <Localize
                                                    wrap tag   = "p"
                                                    cadVersion = { `${ item.version }.${ item.correction }` }
                                                >
                                                    STAND.Text_CADVersion
                                                </Localize>
                                                { item.file_path ?
                                                    <>
                                                        <p className = "date">
                                                            { getDate.formated(item.updated_at) }
                                                        </p>
                                                        <a className  = "link-text inverted"
                                                           href       = { item.file_path }
                                                           target     = "_blank"
                                                           aria-label = "download"
                                                        >
                                                            <Icons.Download/>
                                                        </a>
                                                    </> :
                                                    <Localize
                                                        wrap tag  = "p"
                                                        className = "no-file"
                                                    >
                                                        STAND.NoData_Text_CadFile
                                                    </Localize>
                                                }
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                    };
                })
            }
        />
    );
};

export default StandCadVersionList;