import React, { JSX }  from "react";

import { useQuery } from "@apollo/client";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";

import { GET_STAND } from "graphql/query/stand-q.gql";

import Localize from "../../service/Localize";
import { Blocks, Buttons, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";
import Stand from "components/stand";


const StandPage : React.FC = () : JSX.Element | null => {

    const pParams = useParams(),
          location = useLocation() as { state : { fromPage ?: string } },
          { ee } = useOutletContext<{ee : any}>(),
          isStandCreate = pParams.standId === "create";

    const { data : { stand } = { },
        loading,
        error
    } = useQuery( GET_STAND, {
        variables : {
            id : pParams.standId!
        },
        skip : isStandCreate,
        fetchPolicy : "cache-first"
    });

    if(error) return null;

    return(
        <div className="page stand-page">
            <Blocks.PageHeader
                loading = { loading }
                title   = {
                    (isStandCreate || !stand) ?
                        <Localize>STAND.Page_Title_CreateStand</Localize> :
                        <Stand.Blocks.PageTitle
                            stand={ stand }
                        />
                }
                backButton = {
                    <Buttons.PageBack
                        path = {
                            location.state?.fromPage === 'graphics-list' ?
                                ROUTES.GRAPHIC_LIST_FULL : ROUTES.STAND_LIST_FULL
                        }
                        text = {
                            location.state?.fromPage === 'graphics-list' ?
                               'NAVIGATION.BackButton_Text_ToGraphicsList' :
                                "NAVIGATION.BackButton_Text_ToStandList"
                        }
                    />
                }
            />
            <Menus.ThirdLvl
                items = {[
                    {
                        path : ROUTES.STAND_ITEM_FULL,
                        label : <Localize>STAND.Menu_Label_StandDetails</Localize>,
                        end : true
                    },
                    {
                        path : ROUTES.STAND_ITEM_EQUIPMENT_FULL,
                        label : <Localize>STAND.Menu_Label_Equipments</Localize>,
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_GRAPHIC_FULL,
                        label : <Localize>STAND.Menu_Label_Graphics</Localize>,
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_TASKS_FULL,
                        label : <Localize>STAND.Menu_Label_Tasks</Localize>,
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_TYPE_INFO_FULL,
                        label : <Localize>STAND.Menu_Label_StandTypeInfo</Localize>,
                        disabled : isStandCreate
                    }
                ]}
            />
            <div className="page-content-wrap">
                { loading ?
                    <Loader type="block" /> :
                    <Outlet
                        context={{
                            ee : ee,
                            stand,
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default StandPage;