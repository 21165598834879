import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Outlet, useOutletContext } from "react-router-dom";

import { GET_CHECKLISTS } from "graphql/query/checklist-q.gql";

import CheckList from "components/check-list";
import type { TCheckListGroups } from "components/check-list/check-list-types";
import { Blocks, Buttons, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";


const { structure } = CheckList.helpers;

const CheckListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();

    const { data : { checkLists } = {}, loading } = useQuery( GET_CHECKLISTS, {
        variables : {
            where : {
                column : "EE_ID",
                operator : "EQ",
                value : ee.id
            },
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const structuredChecklist: TCheckListGroups | undefined = checkLists ? structure(checkLists.data) : undefined;

    return(
        <div className="page check-list-page">
            <Blocks.PageHeader
                title={
                    <Localize exhibitionName={ ee.title }>
                        CHECKLIST.List_PageTitle
                    </Localize>
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <Menus.ThirdLvl
                items={[
                    {
                        path : ROUTES.CHECK_LIST_EXPO_DATES_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="ExpoDates"
                                structuredCheckList={ structuredChecklist }
                            />
                    },
                    {
                        path : ROUTES.CHECK_LIST_CRAFTS_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="Crafts"
                                structuredCheckList={ structuredChecklist }
                            />
                    },
                    {
                        path : ROUTES.CHECK_LIST_SITE_PLANNING_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="SitePlanning"
                                structuredCheckList={ structuredChecklist }
                            />
                    },
                    {
                        path : ROUTES.CHECK_LIST_PARTICIPANTS_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="Participants"
                                structuredCheckList={ structuredChecklist }
                            />
                    },
                    {
                        path : ROUTES.CHECK_LIST_INVOICE_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="Invoice"
                                structuredCheckList={ structuredChecklist }
                            />
                    },
                    {
                        path : ROUTES.CHECK_LIST_RECALCULATION_FULL,
                        label :
                            <CheckList.Blocks.MenuLabel
                                sectionName="Recalculation"
                                structuredCheckList={ structuredChecklist }
                            />
                    },

                ]}
            />
            <div className="page-content-wrap">
                { loading ?
                    <Loader /> :
                    <Outlet
                        context={{
                            ee,
                            checkLists : structuredChecklist
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default CheckListPage;