import { ETaskFilterFieldTypes } from "../task-types";

import type { TTaskFilterGroups, TTaskFilters } from "../task-types";
import type { TFilterFieldItem, TFilterFields } from "common/types";
import type { TWhereCondition } from "graphql/type/common-gql-types";


const graphicsFilterParse = (filters : TTaskFilters) : TFilterFieldItem<ETaskFilterFieldTypes>[] => {

    const fields : TFilterFieldItem<ETaskFilterFieldTypes>[] = [];

    (Object.keys(filters) as Array<TTaskFilterGroups | 'text'>).forEach( ( group ) => {

        if(group !== 'text'){
            const filterGroup = filters[group] as Omit<TFilterFields, 'text'>;

            Object.keys(filterGroup).forEach(( filterItemKey ) => {

                const item = filterGroup[filterItemKey] as TWhereCondition;
                let type;

                switch(`${ group }_${filterItemKey}`){
                    case 'where_taskImportance'       : type = ETaskFilterFieldTypes.TASK_IMPORTANCE; break;
                    case 'where_taskStatus'           : type = ETaskFilterFieldTypes.TASK_STATUS; break;
                    case 'where_taskType'             : type = ETaskFilterFieldTypes.TASK_TYPE; break;
                    case 'where_taskAssignedTo'       : type = ETaskFilterFieldTypes.TASK_ASSIGNED_TO; break;

                    case 'whereCad_cadStatus'         : type = ETaskFilterFieldTypes.CAD_STATUS; break;
                    case 'whereCad_cadNumber'         : type = ETaskFilterFieldTypes.CAD_NUMBER; break;

                    case 'whereStand_hall'            : type = ETaskFilterFieldTypes.HALL; break;
                    case 'whereStand_block'           : type = ETaskFilterFieldTypes.BLOCK; break;
                    case 'whereStand_companyName'     : type = ETaskFilterFieldTypes.COMPANY_NAME; break;
                    case 'whereStand_standNumber'     : type = ETaskFilterFieldTypes.STAND_NUMBER; break;
                    case 'whereStand_projectManager'  : type = ETaskFilterFieldTypes.PROJECT_MANAGER; break;
                    case 'whereStand_autocadEngineer' : type = ETaskFilterFieldTypes.AUTOCAD_ENGINEER; break;
                }

                const value = typeof item.value === 'string' ? item.value.replace('%', '') : item.value;

                fields.push({
                    key : Date.now().toString(),
                    type : type!,
                    saveable : true,
                    label : item.label,
                    value : value as string
                });
            });
        }
    });

    return fields;
};

const graphicsFilterBuild = (fields : TFilterFieldItem<ETaskFilterFieldTypes>[]): Omit<TTaskFilters, 'text'> => {

    const where: TTaskFilters["where"] = {};
    const whereStand: TTaskFilters["whereStand"] = {};
    const whereCad: TTaskFilters["whereCad"] = {};


    fields.forEach( (item) => {

        if(item.saveable && item.value !== '') {
            const filter : TWhereCondition = {
                column : '',
                operator : "EQ",
                value : item.value,
                label : item.label
            };

            switch( item.type ){
                case ETaskFilterFieldTypes.TASK_STATUS:
                    where[item.type] = { ...filter, column : 'STATUS' };
                break;
                case ETaskFilterFieldTypes.TASK_IMPORTANCE:
                    where[item.type] = { ...filter, column : 'IMPORTANCE' };
                break;
                case ETaskFilterFieldTypes.TASK_TYPE:
                    where[item.type] = { ...filter, column : 'TYPE' };
                break;
                case ETaskFilterFieldTypes.TASK_ASSIGNED_TO:
                    where[item.type] = { ...filter, column : 'ASSIGNED_TO_ID' };
                break;

                case ETaskFilterFieldTypes.CAD_STATUS:
                    whereCad[item.type] = { ...filter, column : 'STATUS' };
                break;
                case ETaskFilterFieldTypes.CAD_NUMBER:
                    whereCad[item.type] = {
                        ...filter,
                        column : 'CAD_NUMBER',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;

                case ETaskFilterFieldTypes.HALL:
                    whereStand[item.type] = { ...filter, column : 'HALL_ID' };
                break;
                case ETaskFilterFieldTypes.COMPANY_NAME:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'COMPANY_NAME',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case ETaskFilterFieldTypes.BLOCK:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'BLOCK',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case ETaskFilterFieldTypes.STAND_NUMBER:
                    whereStand[item.type] = {
                        ...filter,
                        column : 'STAND_NUMBER',
                        operator : 'LIKE',
                        value : `${item.value}%`
                    };
                break;
                case ETaskFilterFieldTypes.PROJECT_MANAGER:
                    whereStand[item.type] = { ...filter, column : 'PROJECT_MANAGER_ID' };
                break;
                case ETaskFilterFieldTypes.AUTOCAD_ENGINEER:
                    whereStand[item.type] = { ...filter, column : 'AUTOCAD_ENGINEER_ID' };
                break;
            }
        }
    });

    return {
        ...(Object.keys(where).length > 0 && { where }),
        ...(Object.keys(whereStand).length > 0 && { whereStand }),
        ...(Object.keys(whereCad).length > 0 && { whereCad }),
    };
};


const graphicsFilterHelper = {
    parse : graphicsFilterParse,
    build : graphicsFilterBuild
};

export default graphicsFilterHelper;