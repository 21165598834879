import React, { JSX }  from "react";

import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { Blocks, Buttons, Menus } from "components/layout";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";


const ExhibitionDetailsPage : React.FC = () : JSX.Element | null => {

    const pParams = useParams(),
        { ee } = useOutletContext<{ee : any}>(),
        isEeCreate = pParams.eeId === "create";

    return(
        <div className="page exhibition-page">
            <Blocks.PageHeader
                title={
                    isEeCreate || !ee ?
                        <Localize>
                            EXHIBITION.Page_Title_CreateExhibition
                        </Localize> :
                        ee.title
                }
                backButton = {
                    <Buttons.PageBack
                        path = { ROUTES.EXHIBITION_LIST }
                        text = "NAVIGATION.BackButton_Text_ToExhibitions"
                    />
                }
            />
            <Menus.ThirdLvl
                items={[
                    {
                        path : ROUTES.EXHIBITION_DETAILS_FULL,
                        label :
                            <Localize>EXHIBITION.Menu_Label_ExhibitionInfo</Localize>,
                        end : true
                    },
                    {
                        path : ROUTES.EXHIBITION_HALLS_FULL,
                        label : <Localize>EXHIBITION.Menu_Label_Halls</Localize>,
                        disabled : isEeCreate
                    },
                    {
                        path : ROUTES.EXHIBITION_TEAM_FULL,
                        label : <Localize>EXHIBITION.Menu_Label_Team</Localize>,
                        disabled : isEeCreate
                    },
                    {
                        path : ROUTES.EXHIBITION_STATISTICS_FULL,
                        label : <Localize>EXHIBITION.Menu_Label_Statistics</Localize>,
                        disabled : isEeCreate
                    }
                ]}
            />
            <div className="page-content-wrap">
                <Outlet
                    context={{
                        ee : ee
                    }}
                />
            </div>
        </div>
    );
};

export default ExhibitionDetailsPage;