import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { generatePath, Link, useParams } from "react-router-dom";

import { GET_TASKS } from "graphql/query/task-q.gql";

import TaskBlocks from "../blocks";
import TaskHelpers from "../helpers";
import { whereConditionBuilder } from "common/utils";
import { getDate } from "common/utils";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";
import { TagStandard } from "components/service";
import Localize from "components/service/Localize";

import { EDateType } from "common/types";
import type { TID, TPageInfo } from "graphql/type/common-gql-types";


interface ITaskListProps {
    standId : TID
    teamId  : TID
    eeId    : TID
    withHeader ?: boolean
}

const TaskAllList : React.FC<ITaskListProps> = ({
        standId,
        teamId,
        eeId,
        withHeader = true
    }) : JSX.Element => {

    const pParams = useParams();

    const { data, loading } = useQuery( GET_TASKS, {
        variables : {
            where : whereConditionBuilder({
                standId : {
                    column : "STAND_ID",
                    operator : "EQ",
                    value : standId
                },
                status : {
                    column : "STATUS",
                    operator : 'NEQ',
                    value : 'draft'
                }
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-first",
    });

    const {
        tasksCursor : {
            pageInfo = {} as TPageInfo,
            edges : tasks = []
        } = {}
    } = data || {};

    return (
        <div className="task-list-wrap">
            { withHeader &&
                <TaskBlocks.ListHeader
                    standId    = { standId }
                    teamId     = { teamId }
                    totalTasks = { pageInfo.total || 0 }
                />
            }
            <ul className="task-list">
                { tasks && tasks.map(
                    ({ node : task } ) : JSX.Element => {
                        const { relAssignedTo } = task;
                        return (
                            <li
                                className={`task-list-item ${ pParams.taskId === task.id ? "focused" : "" }`}
                                key={ task.id }
                            >
                                <Link to={
                                    generatePath(
                                        ROUTES.STAND_ITEM_TASK_FULL,
                                        {
                                            taskId : Number(task.id),
                                            eeId : Number(eeId),
                                            standId : Number(standId)
                                        }
                                    )
                                }>
                                    <div className="row state">
                                        <TagStandard className = { TaskHelpers.status(task.status) }>
                                            <Localize>{`VARIABLE_ITEM.${ task.status }`}</Localize>
                                        </TagStandard>
                                        <TaskHelpers.Importance
                                            name={ task.importance }
                                        />
                                    </div>
                                    <div className="row info">
                                        <Localize wrap className="type">
                                            {`VARIABLE_ITEM.${ task.type }`}
                                        </Localize>
                                        {task.cad_number &&
                                            <>
                                            |<span className="cad-number">{ task.cad_number  }</span>
                                            </>
                                        }
                                    </div>
                                    { task.title &&
                                        <div className="row title"> { task.title }</div>
                                    }
                                    <div className="row additional-info">
                                        <div className="assign-to">
                                            { relAssignedTo &&
                                                <>
                                                    <Icons.Profile type="simple"/>
                                                    { relAssignedTo.name } { relAssignedTo.surname }
                                                </>
                                            }
                                        </div>
                                        <div className="comments">
                                            <Icons.Comment/> { task.total_comment || 0 }
                                        </div>
                                        <div className="date">
                                            <Icons.Calendar/> { getDate.formated(task.created_at, EDateType.DATETIME) }
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        );
                    }
                )}
            </ul>
            { loading && <Loader type="block" /> }
        </div>
    );
};

export default TaskAllList;