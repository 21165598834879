import { TWhereCondition } from "graphql/type/common-gql-types";

type TFilterObj = {
    [ key : string ] : any
};

const recursiveWhere = ( arr: any[] , condition : string ) : TWhereCondition => {

    const [ whereItem, ...newArr ] = arr;

    if(newArr.length){
        return {
            ...whereItem,
            [ condition ] : [ { ...recursiveWhere( newArr, condition) } ]
        };
    }

    return whereItem;

};


export const whereConditionBuilder = ( filterObj : TFilterObj, condition: string = "AND" ) : TWhereCondition => {

    const whereArr : TWhereCondition[] = [];

    for( const [ key, item  ] of Object.entries( filterObj ) ){

        delete item.label;

        if( typeof item === "object" ){
            if( Object.keys( item ).length ){

                whereArr.push( { ...item } );

            }
        } else {
            if( item ){
                whereArr.push(
                    {
                        column : key.toUpperCase(),
                        operator : "IN",
                        value : item
                    }
                );
            }
        }

    }

    return recursiveWhere( whereArr.reverse(), condition );

};
