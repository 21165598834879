import React, { JSX } from "react";

import { TEquipmentItem, TEquipmentVariationItem } from "graphql/type/equipment-gql-types";

import Localize from "../../service/Localize";
import { PhotoCarousel } from "components/service";


interface IEquipmentInfoBlockProps{
    equipment  : TEquipmentItem
    variation ?: TEquipmentVariationItem
}

const EquipmentInfoBlock : React.FC<IEquipmentInfoBlockProps> = ({ equipment, variation }) : JSX.Element => {

    return(
        <div className="equipment-info-block">
            <PhotoCarousel
                photos={equipment.getImages}
            />
            <h3>{equipment.title}</h3>
            { variation &&
                <div className="attr-grid grid-two-col">
                    {variation.title &&
                        <div>
                            <Localize
                                wrap tag  = "h4"
                                className = "label"
                            >
                                EQUIPMENT.Field_Label_OrganizerName
                            </Localize>
                            <p>{variation.title}</p>
                        </div>
                    }
                    <div>
                        <Localize
                            wrap tag  = "h4"
                            className = "label"
                        >
                            EQUIPMENT.Field_Label_Price
                        </Localize>
                        <p>{variation.price} €</p>
                    </div>
                    <div>
                        <Localize
                            wrap tag  = "h4"
                            className = "label"
                        >
                            EQUIPMENT.Field_Label_OrganizerNumber
                        </Localize>
                        <p>{variation.article}</p>
                    </div>
                </div>
            }

            <div className="attr-grid grid-two-col">
                <div>
                    <Localize
                        wrap tag  = "h4"
                        className = "label"
                    >
                        EQUIPMENT.Field_Label_NavisionNumber
                    </Localize>
                    <p>{equipment.navision_number}</p>
                </div>
                <div>
                    <Localize
                        wrap tag  = "h4"
                        className = "label"
                    >
                        EQUIPMENT.Field_Label_OctacadNumber
                    </Localize>
                    <p>{equipment.octacad_number}</p>
                </div>
                <div>
                    <Localize
                        wrap tag  = "h4"
                        className = "label"
                    >
                        EQUIPMENT.Field_Label_Supplier
                    </Localize>
                    <p>{equipment.supplier.title}</p>
                </div>
                <div>
                    <Localize
                        wrap tag  = "h4"
                        className = "label"
                    >
                        EQUIPMENT.Field_Label_SupplierNumber
                    </Localize>
                    <p>{equipment.supplier_article}</p>
                </div>
            </div>
            {equipment.description &&
                <div className="description">
                    { equipment.description }
                </div>
            }
        </div>

    );
};


export default EquipmentInfoBlock;