import React, { JSX } from "react";

import { ECheckListType } from "../../check-list-types";
import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";
import { localizeByKey } from "components/service/Localize";

import type {  IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    money : 0,
    date : undefined
};

interface ICheckListMoneyDateProps extends IFieldBlockProps{
    viceVersa ?: boolean
    placeholder ?: string | {
        money: string
        date: string
    }
}

const { parseValues } = CheckListHelpers;

const CheckListMoneyDateGroupBlock : React.FC<ICheckListMoneyDateProps> = ({
        values,
        type,
        placeholder,
        viceVersa,
        ...props
    }) : JSX.Element => {

    const dateType = type === ECheckListType.MONEY_DATE ? ECheckListType.DATE : ECheckListType.DAYDATE;

    const fields = parseValues<typeof values, typeof fieldStructure>(
        {
            values,
            structure : fieldStructure
    });


    return(
        <div className={ `check-list-group grid-two-col ${viceVersa ? 'vice-versa' : ''}` }>
            <div className="col-one">
                <CheckListFields.Money
                    value={ fields.money === null ? 0 : fields.money }
                    placeholder = { typeof placeholder === "object" ? placeholder.money : placeholder }
                    multiField  = {{
                        current : 'money',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
            <div className="col-two">
                <CheckListFields.DatePicker
                    value       = { fields.date }
                    placeholder = { typeof placeholder === "object" ? placeholder.date : localizeByKey('GLOBAL.Datepicker_Placeholder_When') }
                    type        = { dateType }
                    multiField  = {{
                        current : 'date',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
        </div>
    );
};

export default CheckListMoneyDateGroupBlock;