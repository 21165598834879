import React, { JSX } from "react";

import { Input } from "antd";

import StandFields from "../fields";
import StandHelpers from "../helpers";
import { EStandFilterFieldTypes } from "../stand-types";
import Exhibition from "components/exhibition";
import { Blocks } from "components/layout";
import { localizeByKey } from "components/service/Localize";
import Task from "components/task";
import Team from "components/team";
import type { TTeamMember } from "components/team/team-types";

import type { TStandFilters } from "../stand-types";
import { ETeamRoles } from "common/types";


type TStandListFilterProps = {
    ee          : any
    filters     : TStandFilters,
    setFilters  : React.Dispatch<React.SetStateAction<TStandFilters>>
    callBack   ?: () => void
};

const StandListFilterForm : React.FC<TStandListFilterProps> = (
    {
        ee,
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    return (
        <Blocks.Filter.Form
            filterFields     = { StandHelpers.filter.parse(filters) }
            fieldTypes       = { EStandFilterFieldTypes }
            defaultFiledType = { EStandFilterFieldTypes.NONE }
            translationKey   = { 'STAND.Select_Option_Filter' }
            onReset={
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onApply={
                (fields) => {
                    setFilters({
                        text : filters.text,
                        ...StandHelpers.filter.build(fields)
                    });
                    callBack();
                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value, label }
                }) => {

                switch (type){
                    case EStandFilterFieldTypes.BLOCK:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Input />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.HALL:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Exhibition.Fields.HallSelect
                                    eeId  = { ee.id }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.CAD_STATUS:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <StandFields.CadStatus
                                    status    = { 0 }
                                    noColor   = { true }
                                    showEmpty = { true }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.PROJECT_MANAGER:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Team.Fields.UserSelect
                                    modalTitle    = {
                                        localizeByKey('USER.Modal_Title_ChooseProjectManager')
                                    }
                                    teamId        = { ee.team_id }
                                    teamRole      = { ETeamRoles.PROJECT_MANAGER }
                                    currentMember = {
                                        value && label ?
                                            {
                                                id : Number(value),
                                                name : label.split(' ')[0],
                                                surname : label.split(' ')[1]
                                            } as TTeamMember : undefined
                                    }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.AUTOCAD_ENGINEER:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Team.Fields.UserSelect
                                    modalTitle    = {
                                        localizeByKey('USER.Modal_Title_ChooseAutocadEngineer')
                                    }
                                    teamId        = { ee.team_id }
                                    teamRole      = { ETeamRoles.DRAFTSMEN }
                                    currentMember = {
                                        value && label ?
                                            {
                                                id : Number(value),
                                                name : label.split(' ')[0],
                                                surname : label.split(' ')[1]
                                            } as TTeamMember : undefined
                                    }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.TASK_TYPE:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Task.Fields.Type
                                    disabled={ false }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.TASK_IMPORTANCE:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Task.Fields.Importance />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.TASK_STATUS:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Task.Fields.Status
                                    noColor   = { true }
                                    showEmpty = { true }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.TASK_ASSIGNED_TO:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes> { ...fieldSettings }>
                                <Team.Fields.UserSelect
                                    modalTitle    = {
                                        localizeByKey('USER.Modal_Title_ChooseAutocadEngineer')
                                    }
                                    teamId        = { ee.team_id }
                                    teamRole      = { ETeamRoles.DRAFTSMEN }
                                    currentMember = {
                                        value && label ?
                                            {
                                                id : Number(value),
                                                name : label.split(' ')[0],
                                                surname : label.split(' ')[1]
                                            } as TTeamMember : undefined
                                    }
                                />
                            </Blocks.Filter.Item>
                        );
                    case EStandFilterFieldTypes.NONE:
                    default:
                        return (
                            <Blocks.Filter.Item<EStandFilterFieldTypes>
                                { ...fieldSettings }
                                noValue = { true }
                            >
                                <StandFields.FilterTypeSelect
                                    filteredTypes = { filteredTypes as EStandFilterFieldTypes[]}
                                    defaultValue  = { value && value.length ? value as EStandFilterFieldTypes : type! }
                                />
                            </Blocks.Filter.Item>
                        );
                }
            }}
        </Blocks.Filter.Form>
    );
};

export default StandListFilterForm;