import React, { JSX } from "react";

import { useQuery } from "@apollo/client";

import { GET_STAND_TYPE_FILES } from "graphql/query/stand-type-q.gql";

import { whereConditionBuilder } from "common/utils";
import { Icons } from "components/layout";
import { Loader } from "components/request-result";


interface IStandTypeFileListProps {
    standTypeGroupId : string | number
}

const StandTypeFileList : React.FC<IStandTypeFileListProps> = ({ standTypeGroupId }): JSX.Element => {

    const { data, loading } = useQuery( GET_STAND_TYPE_FILES, {
        variables : {
            where : whereConditionBuilder({
                lang : {
                    column : "STAND_TYPE_GROUP",
                    operator : "EQ",
                    value : standTypeGroupId
                },
            })
        },
        fetchPolicy : "cache-first"
    });

    const {
        standTypeFilesCursor : {
            edges : files = []
        } = {}
    } = data || {};

    return (
        <div className="stand-type-list-file-wrap">
            { (!loading && !files.length) &&
                <div className="no-data">
                    <p className="label">No files</p>
                </div>
            }
            { (!loading && !!files.length) &&
                <ul  className="stand-typex-list-file">
                    { files.map(
                        ( { node : file } ) => {
                            return (
                                <li className="stand-type-list-file-item" key={ file.id }>
                                    <div className="icon">
                                        <Icons.Documents type={ file.extension } />
                                    </div>
                                    <div className="info">
                                        <h4>{ file.title }</h4>
                                        <p>{ file.description }</p>
                                    </div>
                                    <div className="action">
                                        <a className = "link-text blue"
                                           href      = { file.path }
                                           target    = "_blank"
                                        >
                                            <Icons.Download />
                                        </a>
                                    </div>
                                </li>
                            );
                        })
                    }

                </ul>
            }
            { loading && <Loader /> }
        </div>
    );
};

export default StandTypeFileList;