import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_GRAPHICS_ORDERS } from "graphql/query/graphics-q.gql";

//import graphicsOrdersMock from "../../../mock/graphics.mock";
import { useGraphicsSuppliers } from "../../use-hook";
import GraphicsHelpers from "../helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TGraphicsFilters } from "../graphics-types";
import type { TID } from "graphql/type/common-gql-types";


type IGraphicsTableProps = {
    standId     ?: TID
    eeId         : TID
    organizerId  : TID
    filters      : TGraphicsFilters
};

const GraphicsOrdersTable : React.FC<IGraphicsTableProps> = (
    {
        filters,
        organizerId,
        eeId
    }) : JSX.Element  => {

    const { text,  ...filterGroups  } = filters;

    const { data : { graphicsOrdersCursor } = { },
        loading,
        // error
    } = useQuery( GET_GRAPHICS_ORDERS, {
        variables : {
            text : text,
            where : whereConditionBuilder({
                eeId : !eeId ? {} : {
                    column : "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
                ...filterGroups.where,
            }),
            ...(filterGroups.whereStand && {
                whereStand : whereConditionBuilder(filterGroups.whereStand)
            }),
            orderBy : [ { column : 'STAND_ID', order : 'DESC' }, { column : "ID", order : "ASC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { edges : graphicsOrders = [] } = graphicsOrdersCursor || {};

    const { columns, dataMap, totalColumnWidth } = GraphicsHelpers.table;

    const { graphicsSuppliers } = useGraphicsSuppliers({ organizerId : organizerId });

    return(
        <div className="graphics-orders-table-wrap">
            { !!graphicsOrders.length &&
                <Table
                    className   = "graphics-orders-table"
                    columns     = { columns! }
                    dataSource  = { dataMap( graphicsOrders, graphicsSuppliers ) }
                    scroll      = {{ x : totalColumnWidth() }}
                    pagination  = { false }
                />
            }
            { loading && <Loader type={ "block" } /> }
        </div>
    );
};

export default GraphicsOrdersTable;