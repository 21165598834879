import React, { JSX, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Form, FormInstance } from "antd";

import { STAND_CAD_UPDATE } from "graphql/mutation/stand-m.gql";

import StandFields from "../fields";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface IStandCadStatusFormProps {
    standId      : TID
    currentCad   : TStandCad
    cadEditable ?: boolean
    setForm     ?: (form :  FormInstance) => void
    onUpdate    ?: (callback ?: () => void) => void
}

const StandCadStatusForm : React.FC<IStandCadStatusFormProps> = (
    {
        standId,
        cadEditable = false,
        currentCad,
        setForm,
        onUpdate = () => {}
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ standCadChange, { loading } ] = useMutation(
        STAND_CAD_UPDATE,
        {
            update(cache, { data }) {

                const { 'standCadChange' : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);
                onUpdate();
            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const [ form ] = Form.useForm();

    useEffect(() => {
        form.setFieldValue('status', currentCad.status);
    }, [ currentCad ]);

    useEffect(() => {
        if(setForm){
            setForm(form);
        }
    }, []);

    return(
        <div className="stand-cad-status-form">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues = {{
                    cadId : currentCad.id,
                    status : currentCad.status,
                }}
                onValuesChange = {
                    (changedValues) => {
                        if (!cadEditable && changedValues.status) {
                            setTimeout( () => form.submit(), 1 );
                        }
                    }
                }
                onFinish={(values) => {
                    if(!loading && values.cadStatus !== currentCad.status){
                        standCadChange({
                            variables : {
                                input : {
                                    stand_id : standId ,
                                    cad_id : cadEditable ? values.cadId : currentCad.id,
                                    cad_status : values.status
                                }
                            }
                        }).catch(nError);
                    }
                }}
            >
                { cadEditable ?
                    <Form.Item
                        label = { <Localize>STAND.Field_Label_CadVersion</Localize> }
                        name  = "cadId"
                        rules = {[
                            {
                                required : cadEditable,
                                message : "required"
                            }
                        ]}
                    >
                        <StandFields.CadSelect
                            cadId   = { currentCad.id }
                            standId = { standId }
                        />
                    </Form.Item> :
                    <div className = "cad-version-wrap">
                        <Localize wrap tag="h5" className="label">
                            STAND.Text_CurrentVersion
                        </Localize>
                        <div className = "cad-version">
                            <p>
                                <Localize
                                    wrap
                                    className  = "version"
                                    cadVersion = { currentCad.version }
                                >
                                    STAND.Text_CADVersion
                                </Localize>
                                <span className = "number">
                                    | <Localize cadNumber={currentCad.cad_number}>STAND.Text_CADNumber</Localize>
                                </span>
                            </p>
                            { currentCad.file_path && currentCad.file_path.length ?
                                <a className  = "link-text inverted"
                                   href       = { currentCad.file_path }
                                   target     = "_blank"
                                   aria-label = "download"
                                >
                                    <Icons.Download/>
                                </a> :
                                <Localize
                                    wrap tag  = "p"
                                    className = "no-file"
                                >
                                    STAND.NoData_Text_CadFile
                                </Localize>
                            }
                        </div>
                    </div>
                }
                <Form.Item
                    label = { cadEditable ? "Status" : null }
                    name  = "status"
                    rules = {[
                        {
                            required : cadEditable,
                            message : "required"
                        }
                    ]}
                >
                    <StandFields.CadStatus
                        status = { currentCad.status }
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default StandCadStatusForm;