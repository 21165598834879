import { gql, useApolloClient } from "@apollo/client";

import type { TID } from "graphql/type/common-gql-types";


const useOrganizerFragment = ({ orgId }  :  { orgId : TID }) => {

    const client = useApolloClient();

    return client.readFragment({
        id : `Organizer:${ orgId }`,
        fragment : gql`
            fragment OrganizerFields on Organizer {
                id
                title
                et_default_id
                exhibitionTypes {
                    id
                    status
                    title
                }
            }
        `
    });
};

export default useOrganizerFragment;