import React from "react";

import { generatePath, Link } from "react-router-dom";

import { EE_HALL_DELETE } from "graphql/mutation/exhibition-m.gql";

import ExhibitionFields from "../fields";
import { Menus } from "components/layout";
import { ROUTES } from "components/routes";
import { DataOrPlaceholder, DeleteButton, LinkWithIcon } from "components/service";
import Localize from "components/service/Localize";

import type { IExhibitionHallTableDataType } from "../exhibition-types";


const columns  = [
    {
        title :
            <Localize br={ <br /> }>
                HALL.TableColumn_Header_Name
            </Localize>,
        dataIndex : "title",
        columnIndex : "TITLE",
        //sorter: true,
    },
    {
        title :
            <Localize br={ <br /> }>
                HALL.TableColumn_Header_HallNumber
            </Localize>,
        dataIndex : "number",
        columnIndex : "NUMBER",
    },
    {
        title :
            <Localize br={ <br /> }>
                HALL.TableColumn_Header_NumberOfStands
            </Localize>,
        dataIndex : "standsAmount",
        columnIndex : "STANDS_COUNT",
    },
    {
        title :
            <Localize br={ <br /> }>
                HALL.TableColumn_Header_HallPlan
            </Localize>,
        dataIndex : "hallPlan",

    },
    {
        dataIndex : "action",
        width : "40px",
    }
];

type TEEList = {
    node : any
};


const data = ( halls : TEEList[] , eeId : number) : IExhibitionHallTableDataType[] => {

    return halls.map( ( item : any  ) : IExhibitionHallTableDataType => {

        const {
            id,
            title,
            number,
            stands_count,
        } = item;

        return{
            key : id,
            title :
                <Link className="item-link" to={
                    generatePath(
                        ROUTES.EXHIBITION_HALL_FULL,
                        {
                            hallId : Number(id),
                            eeId : eeId
                        }
                    )
                }>
                    { title }
                </Link> ,
            number :
                <DataOrPlaceholder data={ number }>
                    { number }
                </DataOrPlaceholder>,
            standsAmount : stands_count || 0,
            hallPlan :
                <ExhibitionFields.HallPlanFile
                    hall          = { item }
                    uploadBtnType = { "text" }
                />,
            action :
                <Menus.Popover>
                    <LinkWithIcon
                        route       = { "EXHIBITION_HALL_FULL" }
                        routeParams = { { eeId : eeId, hallId : Number(id) } }
                        icon={ "Edit" }
                        className={ "link-text" }
                    >
                        <Localize>
                            GLOBAL.Action_Menu_Edit
                        </Localize>
                    </LinkWithIcon>
                    <DeleteButton
                        className  = { "hall-delete" }
                        buttonType = { "text"  }
                        id         = { Number(id) }
                        query      = { EE_HALL_DELETE }
                        reFetch    = {[ "GetHalls" ]}
                    >
                        <Localize>
                            GLOBAL.Action_Menu_Delete
                        </Localize>
                    </DeleteButton>
                </Menus.Popover>
        };
    });
};

const ExhibitionHallsTableHelper = { columns, data };

export default ExhibitionHallsTableHelper;