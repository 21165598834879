const HOME                      = "/",
      USER_PROFILE              = "/profile",
      EXHIBITION_LIST           = "/exhibitions",
      EXHIBITION_ITEM           = ":eeId",
      EXHIBITION_ITEM_CREATE    = EXHIBITION_LIST + "/create/details",
      EXHIBITION_ITEM_FULL      = EXHIBITION_LIST + "/" + EXHIBITION_ITEM,
      EXHIBITION_DETAILS        = "details",
      EXHIBITION_DETAILS_FULL   = EXHIBITION_ITEM_FULL + "/" + EXHIBITION_DETAILS,
      EXHIBITION_HALLS          = "halls",
      EXHIBITION_HALL           = ":hallId",
      EXHIBITION_HALL_CREATE    = "create",
      EXHIBITION_HALLS_FULL     = EXHIBITION_DETAILS_FULL + "/" + EXHIBITION_HALLS,
      EXHIBITION_TEAM           = "team",
      EXHIBITION_STATISTICS     = "statistics",
      STAND_LIST                = "stand-list",
      STAND_LIST_FULL           = EXHIBITION_ITEM_FULL + "/" + STAND_LIST,
      STAND_ITEM                = ":standId",
      STAND_ITEM_CREATE         = "create",
      STAND_ITEM_FULL           = EXHIBITION_ITEM_FULL + "/" + STAND_LIST + "/" + STAND_ITEM,
      STAND_ITEM_EQUIPMENT      = 'equipment',
      STAND_ITEM_GRAPHIC        = 'graphics',
      STAND_ITEM_TASKS          = 'tasks',
      STAND_ITEM_TASKS_FULL     = STAND_ITEM_FULL + '/' + STAND_ITEM_TASKS ,
      STAND_ITEM_TASK           = ':taskId',
      STAND_ITEM_TYPE_INFO      = 'type-info',
      CHECK_LIST                = "check-list",
      CHECK_LIST_FULL           = EXHIBITION_ITEM_FULL + "/" + CHECK_LIST,
      CHECK_LIST_EXPO_DATES    = "expo-dates",
      CHECK_LIST_CRAFTS        = "crafts",
      CHECK_LIST_SITE_PLANNING = "site-planning",
      CHECK_LIST_PARTICIPANTS  = "participants",
      CHECK_LIST_INVOICE       = "invoice",
      CHECK_LIST_RECALCULATION = "recalculation",
      GRAPHIC_LIST             = "graphic-list",
      LIBRARY_STAND_TYPES      = "#",
      LIBRARY_EQUIPMENT        = "#",
      LIBRARY_FLOORING         = "#",
      TASK_LIST                = "task-list",
      MY_TASKS                 = "#";

const ROUTES  = {
    HOME,
    USER_PROFILE,
    EXHIBITION_LIST,
    EXHIBITION_ITEM,
    EXHIBITION_ITEM_CREATE,
    EXHIBITION_ITEM_FULL,
    EXHIBITION_DETAILS,
    EXHIBITION_DETAILS_FULL,
    EXHIBITION_HALLS,
    EXHIBITION_HALLS_FULL,
    EXHIBITION_HALL,
    EXHIBITION_HALL_FULL : EXHIBITION_HALLS_FULL + "/" + EXHIBITION_HALL,
    EXHIBITION_HALL_CREATE_FULL : EXHIBITION_HALLS_FULL + "/" + EXHIBITION_HALL_CREATE,
    EXHIBITION_TEAM,
    EXHIBITION_TEAM_FULL : EXHIBITION_DETAILS_FULL + "/" + EXHIBITION_TEAM,
    EXHIBITION_STATISTICS,
    EXHIBITION_STATISTICS_FULL : EXHIBITION_DETAILS_FULL + "/" + EXHIBITION_STATISTICS,
    STAND_LIST,
    STAND_LIST_FULL,
    STAND_ITEM,
    STAND_ITEM_FULL,
    STAND_ITEM_CREATE_FULL : `${ STAND_LIST_FULL }/${ STAND_ITEM_CREATE }`,
    STAND_ITEM_EQUIPMENT,
    STAND_ITEM_EQUIPMENT_FULL : `${ STAND_ITEM_FULL }/${ STAND_ITEM_EQUIPMENT }`,
    STAND_ITEM_GRAPHIC,
    STAND_ITEM_GRAPHIC_FULL : `${ STAND_ITEM_FULL }/${ STAND_ITEM_GRAPHIC }`,
    STAND_ITEM_TASKS,
    STAND_ITEM_TASKS_FULL,
    STAND_ITEM_TASK,
    STAND_ITEM_TASK_FULL : `${ STAND_ITEM_TASKS_FULL }/${ STAND_ITEM_TASK }`,
    STAND_ITEM_TYPE_INFO,
    STAND_ITEM_TYPE_INFO_FULL : `${ STAND_ITEM_FULL }/${ STAND_ITEM_TYPE_INFO }`,
    CHECK_LIST,
    CHECK_LIST_FULL : EXHIBITION_ITEM_FULL + "/" + CHECK_LIST,
    CHECK_LIST_EXPO_DATES,
    CHECK_LIST_EXPO_DATES_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_EXPO_DATES,
    CHECK_LIST_CRAFTS,
    CHECK_LIST_CRAFTS_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_CRAFTS,
    CHECK_LIST_SITE_PLANNING,
    CHECK_LIST_SITE_PLANNING_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_SITE_PLANNING,
    CHECK_LIST_PARTICIPANTS,
    CHECK_LIST_PARTICIPANTS_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_PARTICIPANTS,
    CHECK_LIST_INVOICE,
    CHECK_LIST_INVOICE_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_INVOICE,
    CHECK_LIST_RECALCULATION,
    CHECK_LIST_RECALCULATION_FULL : CHECK_LIST_FULL + "/" + CHECK_LIST_RECALCULATION,
    GRAPHIC_LIST,
    GRAPHIC_LIST_FULL : EXHIBITION_ITEM_FULL + "/" + GRAPHIC_LIST,
    LIBRARY_STAND_TYPES,
    LIBRARY_EQUIPMENT,
    LIBRARY_FLOORING,
    TASK_LIST,
    TASK_LIST_FULL : EXHIBITION_ITEM_FULL + "/" + TASK_LIST,
    MY_TASKS
};

export default ROUTES;