import React, { JSX } from "react";

import { useMutation } from "@apollo/client";

import { CHECKLIST_CREATE, CHECKLIST_UPDATE } from "graphql/mutation/checklist-m.gql";

import CheckListButtons from "../buttons";
import CheckListFields from "../fields";
import { cloneChildren } from "common/utils";
import Localize from "components/service/Localize";
import { useNotification } from "components/use-hook";


import type { ICheckListItem } from "../check-list-types";


interface ICheckListFormRowProps {
    label    ?: string
    children  : React.ReactNode
    rowData  ?: Partial<ICheckListItem>
}

const CheckListFormRowBlock : React.FC<ICheckListFormRowProps> = ({
        label,
        rowData = {},
        children
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const isUpdate = !!rowData.id;

    const [ checklistItemMutate, { loading } ] = useMutation(
        isUpdate ? CHECKLIST_UPDATE : CHECKLIST_CREATE,
        {
            update(cache, { data }) {

                const { [ isUpdate ? "checkListUpdate" : "checkListCreate" ] : {
                    label,
                    message,
                    checkList
                }  } = data;

                if(!isUpdate){
                    cache.modify({
                        fields : {
                            checkLists(existingChecklists = []) {
                                return {
                                    __typename : existingChecklists.__typename,
                                    data : [ { __ref : `CheckList:${ checkList.id }` }, ...existingChecklists.data ]
                                };
                            }
                        }
                    });
                }
                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );
    const handleMutate = <T, >(props : T) => {

        delete rowData.created_at;
        delete rowData.updated_at;
        delete rowData.__typename;

        if(!loading) {
            checklistItemMutate({
                variables : {
                    input : {
                        ...rowData,
                        ...props
                    }
                }
            }).catch(nError);
        }
    };

    return(
        <div className={ `check-list-form-row-block ${ rowData?.flag ? "important" : "" }` }>
            { label ?
                <h4 className="row-title">
                    { label }
                </h4> :
                <Localize className="row-title" tag="h4" wrap>
                    { `CHECKLIST.FieldGroup_Label_${rowData.title}` }
                </Localize>
            }
            <div className="check-list-form-row-data">
                <div className="checkbox-wrap">
                    <CheckListButtons.CheckField
                        handleOnClick = { handleMutate }
                        checked = { rowData.active || false }
                        loading = { loading }
                    />
                </div>
                <div className="field-group-wrap">
                    { cloneChildren(
                        children as React.ReactElement,
                        {
                            values : rowData.list_value,
                            type : rowData.list_type,
                            loading,
                            handleMutate
                        }
                    )}
                </div>
                <div className="important-flag-wrap">
                    <CheckListButtons.ImportantField
                        handleOnClick = { handleMutate }
                        flag    = { rowData.flag || false }
                        loading = { loading }
                    />
                </div>
                <div className="comment-wrap">
                    <CheckListFields.Comment
                        comment      = { rowData.comment || "" }
                        handleOnBlur = { handleMutate }
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(CheckListFormRowBlock);