import React, { JSX } from "react";

import Localize from "../../service/Localize";
import StandForms from "../forms";
import StandLists from "../lists";
import StandModals from "../modals";
import { Blocks } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandCad } from "graphql/type/stand-gql-types";


interface IStandCadInfoBlockProps {
    standId     : TID
    currentCad ?: TStandCad
}

const StandCadInfoBlock : React.FC<IStandCadInfoBlockProps> = ({
        standId,
        currentCad
    }) : JSX.Element => {

    return(
        <Blocks.Card
            Header    = { <Localize>STAND.Block_Title_CAD</Localize> }
            Action    = {
                <>
                    { currentCad ?
                        <StandModals.CadChangeStatus
                            standId    = { standId }
                            currentCad = { currentCad }
                            btnClass   = { 'inverted' }
                        /> : null
                    }
                </>

            }
            className = { "stand-cad-info-block" }
        >
            { currentCad ?
                <>
                    <StandForms.CadStatus
                        standId     = { standId }
                        currentCad  = { currentCad }
                        cadEditable = { false }
                    />
                    <div className = "cad-versions-wrap">
                        <Localize
                            wrap tag   = "h5"
                            className  = "label"
                        >
                            STAND.Text_VersionList
                        </Localize>
                        <StandLists.CadVersion
                            standId    = { standId }
                            currentCad = { currentCad }
                        />
                    </div>
                </> :
                <div className="no-data">
                    <Localize
                        wrap tag   = "p"
                        className  = "label"
                    >
                        STAND.NoData_Text_Cads
                    </Localize>
                </div>
            }
        </Blocks.Card>
    );
};

export default StandCadInfoBlock;