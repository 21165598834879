import StandCadSelect from "./Stand-cad-select";
import StandCadStatusSelect from "./Stand-cad-status-select";
import StandListFilterTypesSelect from "./Stand-list-filter-types-select";
import StandOrientationSelect from "./Stand-orientation-select";
import StandTypeSelect from "./Stand-type-select";

import "./stand-fields.scss";


const StandFields = {
    TypeSelect : StandTypeSelect,
    OrientationSelect : StandOrientationSelect,
    CadStatus : StandCadStatusSelect,
    CadSelect : StandCadSelect,
    FilterTypeSelect : StandListFilterTypesSelect
};

export default StandFields;