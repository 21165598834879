import ExhibitionHallPlanFile from "./Exhibition-hall-plan-file";
import ExhibitionHallSelect from "./Exhibition-hall-select";
import ExhibitionListFilterTypesSelect from "./Exhibition-list-filter-type-select";
import ExhibitionOrganizerSelect from "./Exhibition-organizer-select";
import ExhibitionStatusSelect from "./Exhibition-status-select";
import ExhibitionTypeSelect from "./Exhibition-type-select";

import "./exhibition-fields.scss";


const ExhibitionFields = {
    TypeSelect : ExhibitionTypeSelect,
    OrganizerSelect : ExhibitionOrganizerSelect,
    HallPlanFile : ExhibitionHallPlanFile,
    HallSelect : ExhibitionHallSelect,
    FilterTypeSelect : ExhibitionListFilterTypesSelect,
    StatusSelect : ExhibitionStatusSelect
};

export default ExhibitionFields;