import { gql, TypedDocumentNode } from "@apollo/client";

import { IQueryVariables, TWhereCondition } from "../type/common-gql-types";
import {
    TGraphicsLayoutCursorData,
    TGraphicsOrderCursorData,
    TGraphicsSupplierCursorData,
} from "../type/graphics-gql-types";

interface IGraphicsQueryVariables extends IQueryVariables{
    whereStand ?: TWhereCondition
}

export const GET_GRAPHICS_ORDERS: TypedDocumentNode<{ graphicsOrdersCursor : TGraphicsOrderCursorData }, IGraphicsQueryVariables > = gql`
    query GetGraphicsOrders (
        $text       : String
        $where      : QueryGraphicsOrdersCursorWhereWhereConditions
        $whereStand : QueryGraphicsOrdersCursorWhereStandWhereHasConditions
        $orderBy    : [QueryGraphicsOrdersCursorOrderByOrderByClause!]
        $after      : String
    ){
        graphicsOrdersCursor(
            text       : $text
            where      : $where
            whereStand : $whereStand
            orderBy    : $orderBy
            first      : 50
            after      : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    number
                    stand_id
                    graphic_supplier_id
                    status
                    mp_status
                    quantity
                    product_type
                    material
                    color
                    visible_width
                    visible_height
                    actual_width
                    actual_height
                    montage
                    montage_comment
                    component
                    price_offer
                    area_order
                    area_paid
                    supplier_order
                    price
                    price_montage
                    total
                    relStand {
                        id
                        ee_id
                        company_name
                        hall_title
                        block
                        stand_number
                        proauf
                    }
                    relPrintData : relGraphicsPrintDatas {
                        id
                        graphics_order_id
                        status
                        type
                        url_storage
                        server_name
                        extension
                        title
                        description
                        order
                        getPathPublic
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;


export const GET_GRAPHICS_SUPPLIERS: TypedDocumentNode<{ graphicsSuppliersCursor : TGraphicsSupplierCursorData }, IQueryVariables > = gql`
    query GetGraphicsSuppliers (
        $text    : String
        $where   : QueryGraphicsSuppliersCursorWhereWhereConditions
        $orderBy : [QueryGraphicsSuppliersCursorOrderByOrderByClause!]
        $after   : String
    ){
        graphicsSuppliersCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 30
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                    organizer_id
                    supplier_id
                    relVariableList {
                        id
                        group_name
                        slug
                    }
                    relRule {
                        id
                        title
                        graphic_supplier_id
                        price_manufacture
                        price_on_supplier
                        price_on_stand
                        price_additional
                        free_symbols
                        lettering
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_GRAPHICS_LAYOUTS: TypedDocumentNode<{ graphicsLayoutsCursor : TGraphicsLayoutCursorData }, IQueryVariables > = gql`
    query GetGraphicsLayoutsCursor (
        $text    : String
        $where   : QueryGraphicsLayoutsCursorWhereWhereConditions
        $orderBy : [QueryGraphicsLayoutsCursorOrderByOrderByClause!]
        $after   : String
    ){
        graphicsLayoutsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 30
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    status
                    server_name
                    extension
                    title
                    getPathPublic
                    order
                    created_at
                    updated_at
                }
            }
        }
    }
`;
