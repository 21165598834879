import React, { JSX } from 'react';

import { InputNumber } from 'antd';

import { ICheckListItem, IFieldProps } from "../check-list-types";


const CheckListMoneyField : React.FC<IFieldProps> = ({
        value,
        loading,
        handleMutate,
        multiField
    }) : JSX.Element => {


    return(
        <InputNumber<number>
            defaultValue = { typeof value === "string" && value !== "" ? parseFloat(value) : value || 0 }
            precision    = { 2 }
            formatter    = {(value) => `${value} €`}
            parser       = {
                (value) => value ? parseFloat(value.replace(" €", "")) : 0
            }
            controls     = { false }
            onBlur       = {
                (e : React.ChangeEvent<HTMLInputElement>) => {
                    const eValue = e.target.value;

                    if(!loading && value !== eValue && handleMutate !== undefined){
                        let listValue = eValue;

                        if(multiField){
                            listValue = JSON.stringify({
                                ...multiField.structure,
                                [multiField.current] : eValue,
                            });
                        }

                        handleMutate<Pick<ICheckListItem, 'list_value'>>(
                            { list_value : listValue }
                        );
                    }
                }
            }
        />
    );
};

export default CheckListMoneyField;