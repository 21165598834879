import React, { JSX } from "react";

import { ECheckListType } from "../../check-list-types";
import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";
import { localizeByKey } from "components/service/Localize";

import type {  IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    date : undefined,
    time : undefined,
};

interface ICheckListDateTimeProps extends IFieldBlockProps{
    viceVersa ?: boolean
    placeholder ?: string | {
        time: string
        date: string
    }
}

const { parseValues } = CheckListHelpers;

const CheckListDateTimeGroupBlock : React.FC<ICheckListDateTimeProps> = ({
        values,
        type,
        placeholder,
        ...props
    }) : JSX.Element => {

    const dateType = type === ECheckListType.DAYDATE_TIME ? ECheckListType.DAYDATE : ECheckListType.DATE ;

    const fields = parseValues<typeof values, typeof fieldStructure>({
        values,
        structure : fieldStructure
    });

    return(
        <div className="check-list-group grid-two-col">
            <div className="col-one">
                <CheckListFields.DatePicker
                    value       = { fields.date }
                    placeholder = { typeof placeholder === "object" ? placeholder.date : localizeByKey('GLOBAL.Datepicker_Placeholder_When') }
                    type        = { dateType }
                    multiField  = {{
                        current : 'date',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
            <div className="col-two">
                <CheckListFields.TimePicker
                    value       = { fields.time }
                    placeholder = { typeof placeholder === "object" ? placeholder.time : localizeByKey('GLOBAL.Timepicker_Placeholder_Time') }
                    multiField  = {{
                        current : 'time',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
        </div>
    );
};

export default CheckListDateTimeGroupBlock;