import React, { JSX } from "react";

import CheckListHelpers from "../helpers";
import { Icons } from "components/layout";
import Localize from "components/service/Localize";

import type { TCheckListGroups, TCheckListSectionNames } from "../check-list-types";


interface ICheckListMenuLabelProps {
    sectionName : TCheckListSectionNames
    structuredCheckList ?: TCheckListGroups
}

const { fieldStatistic } = CheckListHelpers;

const CheckListMenuLabelBlock : React.FC<ICheckListMenuLabelProps> = (
    {
        sectionName,
        structuredCheckList
    }) : JSX.Element => {

    const { unFinished = undefined } = fieldStatistic(structuredCheckList, sectionName);

    return(
        <Localize
            status={
                unFinished ? `(${ unFinished })` :
                    <Icons.Check
                        loading={ typeof unFinished !== "number" }
                        className="check-icon"
                    />
            }
        >
            {`CHECKLIST.Menu_Label_${sectionName}`}
        </Localize>
    );
};

export default CheckListMenuLabelBlock;