import React, { JSX, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";

import { Icons } from "../layout";
import { useDebounce, useNotification } from "../use-hook";

import type { DocumentNode } from "graphql/index";
import type { TID } from "graphql/type/common-gql-types";


interface ICountFieldProps {
    id       : TID
    qty      : number
    maxQty  ?: number
    mutation : DocumentNode

}

const CountField : React.FC<ICountFieldProps> = ({ id, qty, maxQty, mutation }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ updateQuantity, { loading } ] = useMutation( mutation,
        {
            update(cache, { data }) {

                const operation = getOperationName(mutation);

                const { [ operation! ] : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);

            },
        }
    );

    const [ count, setCount ] = useState<number>(qty);

    const debouncedFilter = useDebounce(
        (newCount : number) => {
            if(!loading){
                updateQuantity({
                    variables : {
                        input : {
                            id, qty : newCount
                        }
                    }
                }).catch(nError);
            }
        }, 1000
    );

    useEffect(() => {
        if(qty !== count)
        debouncedFilter(count);
    }, [ count ]);

    
    return(
        <div className="count-field">
            <button
                className="count-field-btn minus"
                onClick={ () => {
                    if(count - 1 >= 0){
                        setCount(count - 1);
                    }
                }}
            >
                <Icons.Minus />
            </button>
            <span
                className={`count-field-value ${count === 0 ? 'zero' : ''}`}
            >
                { count}
            </span>
            <button
                className="count-field-btn plus"
                onClick={ () => {
                    if(maxQty && count + 1 > maxQty){
                        return false;
                    }
                    setCount(count + 1);
                }}
            >
                <Icons.Plus />
            </button>
        </div>
    );
};

export default CountField;