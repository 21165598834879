import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { USER_LOGOUT } from "graphql/mutation/user-m.gql";
import { IS_LOGGED_IN } from  "graphql/query/local-store.gql";

import { reactiveVarHelper } from "common/helpers";


//import useNotification from "./use-notification";


const useLogout = () => {

    const navigate = useNavigate(),
          location = useLocation(),
          //{ nError } = useNotification(),
          [ userLogout, { client } ] = useMutation( USER_LOGOUT );

    const rVar = reactiveVarHelper();

    const clearStore = ({ route } : { route : string | undefined }) => {

        localStorage.removeItem("authToken");

        client.cache.reset().then(
            () => {
                if(route !== undefined && route){
                    navigate(route ?? "/", {
                        state : {
                            path : location.pathname
                        }
                    });
                    rVar.reset();
                }
            }
        );

    };

    const logout = ({ redirectRoute = "/" }) => {
        userLogout({
            update(cache) {

                cache.writeQuery({
                    query : IS_LOGGED_IN,
                    data : {
                        isLoggedIn : false
                    },
                });

                localStorage.removeItem("authToken");


                setTimeout( () => {
                    clearStore({ route : redirectRoute });
                }, 100 );
            }
        }).catch((error) => console.warn(error)/*nError( error )*/);
    };

    return {
        logout, clearStore
    };

};

export default useLogout;