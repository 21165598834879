import React, { JSX } from "react";

import Exhibition from "components/exhibition";


interface IPageHeaderProps{
    title        : JSX.Element | string
    loading     ?: boolean
    classname   ?: string
    backButton  ?: JSX.Element
    hideEEBlock ?: boolean
}

const PageHeaderBlock : React.FC<IPageHeaderProps> = (
    {
        title,
        backButton,
        loading,
        classname = "",
        hideEEBlock = false
    }) : JSX.Element => {

    return(
        <div className={`page-header-block ${ classname }`}>
            { (backButton && !hideEEBlock) &&
                <div className="row">
                    {backButton &&
                        <div className="page-back-button-wrap">
                            {backButton}
                        </div>
                    }
                    {!hideEEBlock &&
                        <div className="exhibition-selector-wrap">
                            <Exhibition.Blocks.ChangeCurrent/>
                        </div>
                    }
                </div>
            }
            <h1 className="page-title">
                {!loading && title}
            </h1>
        </div>
    );
};


export default PageHeaderBlock;