import React, { JSX } from "react";

import { Modal } from "antd";

import { localizeByKey } from "./Localize";
import { Icons } from "components/layout";

import type { OutsideControlledModal } from "common/types";


const ModalStandard : React.FC<OutsideControlledModal> = ({
        isOpen,
        close,
        confirm,
        extraClass = "",
        children,
        ...props
    }) : JSX.Element => {

    return(
        <Modal
            closeIcon      = { <Icons.Cross /> }
            okText         = { localizeByKey('GLOBAL.Button_Label_Choose') }
            destroyOnClose = { true }
            className      = { `modal-standard ${ extraClass }` }
            open           = { isOpen }
            width          = { "auto" }
            onOk           = { () => confirm() }
            onCancel       = { () =>  close()  }
            centered       = { true }
            { ...props }
        >
            { children }
        </Modal>
    );
};

export default ModalStandard;