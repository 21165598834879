import React from 'react';

import { useMutation } from "@apollo/client";
import { Button, Form, Input } from 'antd';

import { USER_LOGIN } from "graphql/mutation/user-m.gql";
import { IS_LOGGED_IN } from "graphql/query/local-store.gql";
 
//import { errorNotification } from 'components/request-result';
import { Icons } from "components/layout";
import Localize, { localizeByKey } from "components/service/Localize";
import { useAuthRedirect } from "components/use-hook";

 
const SignInForm : React.FC = () => {

    const [ form ] = Form.useForm();
    const authRedirect = useAuthRedirect();

    const [ loginRequest, { loading } ] = useMutation(
        USER_LOGIN,
        {
            update(cache, { data : { userLogin } }) {

                const { access_token } = userLogin;

                localStorage.setItem("authToken", access_token);
                cache.writeQuery({
                    query : IS_LOGGED_IN,
                    data : {
                        isLoggedIn : access_token,
                    },
                });

                authRedirect();
            },
            onError(error) {
                console.error(error);
                //errorNotification(error);
            }
        }
    );

    return (
        <div className="sign-in-form">

            <div className="logo-holder">
                <div className="logo">
                    <Icons.Logo type="full" color="#000000" />
                </div>
            </div>
            <h1>Sign in</h1>
            <Form
                form={ form }
                layout="vertical"
                onFinish={ ({ login, password }) => {
                    loginRequest({
                        variables : {
                            data : {
                                username : login,
                                password : password,
                            }
                        },
                    }).catch((error) => {
                        console.error(error);
                        //errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    label={ <Localize>FORMS.Input_Label_Login</Localize> }
                    name="login"
                    rules={ [
                        {
                            required : true,
                            message : localizeByKey("FORM_RULES.Required_Login")
                        }
                    ] }
                >
                    <Input name="login" />
                </Form.Item>
                <Form.Item
                    label={ <Localize>FORMS.Input_Label_Password</Localize> }
                    name="password"
                    rules={ [
                        {
                            required : true,
                            message : localizeByKey("FORM_RULES.Required_Password")
                        }
                    ] }
                >
                    <Input.Password
                        name="password"
                    />
                </Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                    >
                        <Localize wrap>FORMS.Button_Text_Login</Localize>
                    </Button>
                </div>
            </Form>
        </div>

    );
};


export default SignInForm;