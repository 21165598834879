import ExhibitionEditForm from "./Exhibition-edit-form";
import ExhibitionHallEditForm from "./Exhibition-hall-edit-form";
import ExhibitionListFilterForm from "./Exhibition-list-filter-form";


const ExhibitionForms = {
    Edit : ExhibitionEditForm,
    EditHall : ExhibitionHallEditForm,
    ListFilter : ExhibitionListFilterForm
};


export default ExhibitionForms;