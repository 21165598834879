import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import cache from "./cache";


const httpLink = createUploadLink({
    uri : process.env.REACT_APP_API_ENDPOINT
});


const authMiddleware  = setContext((_, { headers }) => {
    const authToken = localStorage.getItem("authToken");

    return {
        headers : {
            ...headers,
            accept : "application/json",
            authorization : authToken ? `Bearer ${ authToken }` : ""
        }
    };
});

const client = new ApolloClient({
    link : authMiddleware.concat(httpLink),
    cache
});

export default client;
