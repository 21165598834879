import React, { JSX } from "react";

import { generatePath, NavLink, useParams } from "react-router-dom";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import Localize from "components/service/Localize";


const ExhibitionMenu : React.FC = () : JSX.Element => {

    const pParams = useParams(),
          isEeCreate = pParams.eeId === "create";

    return (
        <nav className="exhibition-menu-wrap">
            <ul className="second-lvl-menu exhibition-menu">
                <li>
                    <NavLink to={
                        generatePath(ROUTES.EXHIBITION_DETAILS_FULL, pParams)
                    }>
                        <Icons.ExpoDetails/>
                        <Localize tag="span" wrap className="label">
                            NAVIGATION.Menu_Label_ExpoDetails
                        </Localize>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={generatePath(ROUTES.STAND_LIST_FULL, pParams)}
                             className={isEeCreate ? "link-disabled" : ""}
                             onClick={(event) => isEeCreate ? event.preventDefault() : ""}
                    >
                        <Icons.StandList/>
                        <Localize tag="span" wrap className="label">
                            NAVIGATION.Menu_Label_StandList
                        </Localize>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={generatePath(ROUTES.GRAPHIC_LIST_FULL, pParams)}
                             className={isEeCreate ? "link-disabled" : ""}
                             onClick={(event) => isEeCreate ? event.preventDefault() : ""}
                    >
                        <Icons.GraphicList/>
                        <Localize tag="span" wrap className="label">
                            NAVIGATION.Menu_Label_GraphicsList
                        </Localize>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={generatePath(ROUTES.TASK_LIST_FULL, pParams)}
                             className={isEeCreate ? "link-disabled" : ""}
                             onClick={(event) => isEeCreate ? event.preventDefault() : ""}
                    >
                        <Icons.TaskList/>
                        <Localize tag="span" wrap className="label" br={<br/>}>
                            NAVIGATION.Menu_Label_TaskList
                        </Localize>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={generatePath(ROUTES.CHECK_LIST_FULL, pParams)}
                             className={isEeCreate ? "link-disabled" : ""}
                             onClick={(event) => isEeCreate ? event.preventDefault() : ""}
                    >
                        <Icons.CheckList/>
                        <Localize tag="span" wrap className="label">
                            NAVIGATION.Menu_Label_CheckList
                        </Localize>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default React.memo(ExhibitionMenu);