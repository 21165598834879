import taskFilterHelper from "./task-filter-helper";
import { prepareEditFormDataHelper, prepareEditInitDataHelper } from "./task-form-edit-helper";
import TaskImportanceHelper from "./Task-importance-helper";
import taskStatusClassHelper from "./task-status-helper";
import tasksTableHelper from "./task-table-helper";

const TaskHelpers = {
    editForm : {
        prepareData : prepareEditFormDataHelper,
        initialData : prepareEditInitDataHelper
    },
    filter : taskFilterHelper,
    Importance : TaskImportanceHelper,
    status : taskStatusClassHelper,
    table : tasksTableHelper,
};

export default TaskHelpers;
