import { useNavigate  } from "react-router-dom";

import { reactiveVarHelper } from "common/helpers";


const useAuthRedirect = () => {

    const navigate = useNavigate(),
          publicParams =  reactiveVarHelper("public");

    return () => {

        if( publicParams.get("comeFrom") ){
            return  navigate( publicParams.get("comeFrom" ), { replace : true } );
        }

        return navigate("/", { replace : true });
    };

};

export default useAuthRedirect;