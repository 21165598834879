import React, { JSX } from 'react';

import { useMutation } from "@apollo/client";
import { Button, Form, FormInstance, Input } from 'antd';
import { generatePath, useNavigate } from "react-router-dom";

import { EXHIBITION_CREATE, EXHIBITION_DELETE, EXHIBITION_UPDATE } from "graphql/mutation/exhibition-m.gql";

import ExhibitionFields from "../fields";
import ExhibitionHelpers from "../helpers";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { DeleteButton, RangeDate } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";
import Team from "components/team";
import { useNotification } from "components/use-hook";

import { ETeamRoles } from "common/types";


type TEEFormProps = {
    ee ?: any,
    isCreate : boolean
};

const ExhibitionEditForm : React.FC<TEEFormProps> = ({ isCreate, ee }) : JSX.Element => {

    const navigate = useNavigate(),
        { nError, nSuccess } = useNotification();

    const [ exhibitionEdit, { loading } ] = useMutation(
        isCreate ? EXHIBITION_CREATE : EXHIBITION_UPDATE,
        {
            update(cache, { data }) {

                const { [isCreate ? "eeCreate" : "eeUpdate" ] : {
                    label,
                    message,
                    exhibitionEvent
                }  } = data;

                if(isCreate){
                    navigate(
                        generatePath(
                            ROUTES.EXHIBITION_DETAILS_FULL,
                            { eeId : Number(exhibitionEvent.id) }
                        )
                    );
                }

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const [ form ] = Form.useForm(),
          organizerId : number = Form.useWatch<string, FormInstance>('organizer_id', form),
          teamId : number = Form.useWatch<string, FormInstance>('team_id', form);

    return (
        <div className="exhibition-edit-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues ={
                    !isCreate ? ExhibitionHelpers.editForm.initialData(ee) : undefined
                }
                onFinish={ (values) => {

                    if( !loading ){
                        exhibitionEdit({
                            variables : {
                                input : ExhibitionHelpers.editForm.prepareData(
                                    { ...values, id : isCreate ? null : ee.id }
                                )
                            }
                        }).catch( nError );
                    }

                } }
            >
                <Form.Item
                    label = { <Localize>EXHIBITION.Field_Label_Name</Localize> }
                    name  = "title"
                    rules = {[
                        {
                            required : true,
                            message : "required"
                        }
                    ]}
                >
                    <Input name="ee-name" />
                </Form.Item>
                <Form.Item
                    label = { <Localize>EXHIBITION.Field_Label_AKQ</Localize> }
                    name  = "aquise_number"
                >
                    <Input name="aquiseNumber" />
                </Form.Item>
                <div className="grid-three-col" >
                    <Form.Item
                        label = { <Localize>EXHIBITION.Field_Label_ConstructionPeriod</Localize> }
                        name  = "constructionDate"
                    >
                        <RangeDate />
                    </Form.Item>
                    <Form.Item
                        label = { <Localize>EXHIBITION.Field_Label_RunningPeriod</Localize> }
                        name  = "runningDate"
                    >
                        <RangeDate withTime={ true } />
                    </Form.Item>
                    <Form.Item
                        label = { <Localize>EXHIBITION.Field_Label_DeconstructionPeriod</Localize> }
                        name  = "deconstructionDate"
                    >
                        <RangeDate />
                    </Form.Item>
                </div>
                <Form.Item
                    label = { <Localize>EXHIBITION.Field_Label_Organizer</Localize> }
                    name  = "organizer_id"
                    rules = { [
                        {
                            required : true,
                            message : "required"
                        }
                    ] }
                >
                    <ExhibitionFields.OrganizerSelect />
                </Form.Item>
                { organizerId &&
                    <Form.Item
                        label = { <Localize>EXHIBITION.Field_Label_ExhibitionType</Localize> }
                        name  = "exhibition_type_id"
                        rules = { [
                            {
                                required : true,
                                message : "required"
                            }
                        ] }
                    >
                        <ExhibitionFields.TypeSelect
                            exhibitionTypeId = { isCreate ? undefined :  ee.exhibition_type_id }
                            organizerId      = { organizerId }
                        />
                    </Form.Item>
                }
                <Form.Item
                    label = { <Localize>EXHIBITION.Field_Label_Team</Localize> }
                    name  = "team_id"
                >
                    <Team.Fields.Select />
                </Form.Item>
                { teamId &&
                    <div className="grid-two-col" >
                        <Form.Item
                            label = { <Localize>EXHIBITION.Field_Label_LeadProjectManager</Localize> }
                            name  = "project_manager_id"
                        >
                            <Team.Fields.UserSelect
                                modalTitle    = { localizeByKey("USER.Modal_Title_ChooseLeadProjectManager") }
                                teamId        = { teamId }
                                teamRole      = { ETeamRoles.PROJECT_MANAGER }
                                currentMember = { ee && ee.projectManager ? ee.projectManager : undefined }
                            />
                        </Form.Item>
                        <Form.Item
                            label = { <Localize>EXHIBITION.Field_Label_ConstructionManager</Localize> }
                            name  = "construction_manager_id"
                        >
                            <Team.Fields.UserSelect
                                modalTitle    = { localizeByKey("USER.Modal_Title_ChooseConstructionManager") }
                                teamId        = { teamId }
                                teamRole      = { ETeamRoles.CONSTRUCTION_MANAGER }
                                currentMember = { ee && ee.constructionManager ? ee.constructionManager : undefined }
                            />
                        </Form.Item>
                    </div>
                }
                <div className="form-actions">
                    <div>
                        { !isCreate &&
                            <DeleteButton
                                className  = { "exhibition-delete" }
                                buttonType = { "default" }
                                id         = { Number(ee.id) }
                                query      = { EXHIBITION_DELETE }
                                confirmText= {
                                    localizeByKey(
                                        'EXHIBITION.Modal_Text_DeleteExhibition',
                                        { exhibitionTitle : ee.title }
                                    )
                                }
                            >
                                <Localize wrap>EXHIBITION.Button_Label_DeleteExhibition</Localize>
                            </DeleteButton>
                        }
                    </div>
                    <div>
                        <Button
                            type     = "primary"
                            htmlType = "submit"
                        >
                            <Icons.Edit loading={ loading } />
                            <Localize wrap>
                                { isCreate ?
                                    'GLOBAL.Button_Label_Create' :
                                    'GLOBAL.Button_Label_SaveChanges'
                                }
                            </Localize>
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};


export default React.memo(ExhibitionEditForm);