import React from "react";

import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { Spin } from "antd";


type TLoaderProps = {
    type       ?: "block" | "item" | undefined
    blockClass ?: string
    spinClass  ?: string
};

const Loader : React.FC<TLoaderProps> = ({
          type,
          blockClass = "color-text",
          spinClass,
        }) => {

    if(type === "block") {
        return (
            <div className={`loader-block ${ blockClass }`}>
                <Spin
                    size="default"
                    indicator={<SyncOutlined  spin />}
                    className={ spinClass || "" }
                />
            </div>
        );
    }

    return (
        <div className={`loader-item ${ blockClass }`}>
            <Spin size="large"
                  className={ spinClass || "" }
                  indicator={<LoadingOutlined spin />}
            />
        </div>
    );
};

export default Loader;