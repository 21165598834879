import FilterFormBlock from "./Filter-form-block";
import FilterItemBlock from "./Filter-item-block";


const FilterBlock = {
    Form : FilterFormBlock,
    Item : FilterItemBlock,

};

export default FilterBlock;