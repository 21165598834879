import React, { JSX } from "react";

import dayjs from "dayjs";
import { generatePath, Link } from "react-router-dom";

import { EXHIBITION_DELETE } from "graphql/mutation/exhibition-m.gql";

import {
    IExhibitionsTableDataType
} from "../exhibition-types";
import { Icons, Menus } from "components/layout";
import { ROUTES } from "components/routes";
import { DataOrPlaceholder, DeleteButton, LinkWithIcon } from "components/service";
import Localize, { localizeByKey } from "components/service/Localize";


const columns  = [
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_Exhibition
            </Localize>,
        dataIndex : "title",
        columnIndex : "TITLE",
        //sorter: true,
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_AKQ
            </Localize>,
        dataIndex : "aquiseNumber",
        columnIndex : "AQUISE_NUMBER",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_Status
            </Localize>,
        dataIndex : "status",
        columnIndex : "STATUS ",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_Stands
            </Localize>,
        dataIndex : "standCount",
        columnIndex : "STAND_TOTAL",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_ConstructionDate
            </Localize>,
        dataIndex : "constructionDate",
        columnIndex : "CONSTRUCTION_START_AT",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_RunningDate
            </Localize>,
        dataIndex : "runningDate",
        columnIndex : "RUNNING_START_AT",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_DeconstructionDate
            </Localize>,
        dataIndex : "deconstructionDate",
        columnIndex : "DECONSTRUCTION_START_AT",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_ProjectManager
            </Localize>,
        dataIndex : "projectManager",
        columnIndex : "PROJECT_MANAGER_ID",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_OrganizerType
            </Localize>,
        dataIndex : "organizer",
        columnIndex : "ORGANIZER_ID",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_Area
            </Localize>,
        dataIndex : "area",
        columnIndex : "AREA",
    },
    {
        title :
            <Localize>
                EXHIBITION.TableColumn_Header_Team
            </Localize>,
        dataIndex : "team",
        columnIndex : "TEAM_ID",
    },
    {
        dataIndex : "action",
        width : 50,
    }
];

type TEEList = {
    node : any
};

type TCountStandProps = {
    total       : number | undefined
    inProgress  : number | undefined
    completed   : number | undefined
};

const CountStandField : React.FC<TCountStandProps> = (
        { total, inProgress, completed }
    ) : JSX.Element => {

    return (
        <div className="stand-count">
            <p className="total">
                <Icons.StandCount />
                <span className="count">{ total }</span>
            </p>
            <p className="in-process">
                <Icons.StandCount type={ "inProcess" } />
                <span className="count">{ inProgress }</span>
            </p>
            <p className="completed">
                <Icons.StandCount type={ "completed" } />
                <span className="count">{ completed }</span>
            </p>
        </div>
    );
};

const DateRangeField: React.FC<{ from: string, to: string }> = ({ from, to }) : JSX.Element => {


    if(dayjs(from).format("YYYY") !== dayjs().format("YYYY")){
        return(
            <div>{ dayjs(from).format("DD.MM.YY") }-{ dayjs(to).format("DD.MM.YY") }</div>
        );
    }

    return(
        <div>{ dayjs(from).format("DD MMM.") }-{ dayjs(to).format("DD MMM.") }</div>
    );
};


const data = ( exhibitions : TEEList[]  ) : IExhibitionsTableDataType[] => {

    return exhibitions.map( ( { node  } : { node : any } ) : IExhibitionsTableDataType => {

        const {
            id,
            title,
            aquise_number : akq,
            status,
            construction_start_at,
            construction_end_at,
            running_start_at,
            running_end_at,
            deconstruction_start_at,
            deconstruction_end_at,
            stand_total,
            stand_inprogress_total,
            stand_completed_total,
            area,
            team,
            exhibition_type_id,
            organizer,
            projectManager,
        } = node;

        const { exhibitionTypes = [] } = organizer;

        const exhibitionType = exhibitionTypes.find( (ET : { id : number }) => ET.id === exhibition_type_id );


        return{
            key : id,
            title :
                <Link className="item-link" to={
                    generatePath(
                        ROUTES.EXHIBITION_DETAILS_FULL,
                        { eeId : Number(id) }
                    )
                }>
                    { title }
                </Link> ,
            aquiseNumber :
                <DataOrPlaceholder data={ akq }>
                    { akq }
                </DataOrPlaceholder>,
            standCount :
                <CountStandField
                    total       = { stand_total }
                    completed   = { stand_inprogress_total }
                    inProgress  = { stand_completed_total }
                />,
            status :
                <div className={ `status ${ status }`}>
                    { status }
                </div>,
            constructionDate :
                <DataOrPlaceholder data={ construction_start_at }>
                    <DateRangeField
                        from = { construction_start_at }
                        to   = { construction_end_at }
                    />
                </DataOrPlaceholder>,
            runningDate :
                <DataOrPlaceholder data={ running_start_at }>
                    <DateRangeField
                        from = { running_start_at }
                        to   = { running_end_at }
                    />
                </DataOrPlaceholder>,
            deconstructionDate :
                <DataOrPlaceholder data={ deconstruction_start_at }>
                    <DateRangeField
                        from = { deconstruction_start_at }
                        to   = { deconstruction_end_at }
                    />
                </DataOrPlaceholder>,
            projectManager :
                <DataOrPlaceholder data={ projectManager }>
                    { ( pm ) => <>{ pm.name } { pm.surname }</> }
                </DataOrPlaceholder>,
            organizer :
                <div className="org-type">
                    <p>{ organizer.title }</p>
                    <p>{ exhibitionType.title }</p>
                </div>,
            area : area || 0,
            team :
                <DataOrPlaceholder data={ team }>
                    { (eeTeam) => eeTeam.title }
                </DataOrPlaceholder>,
            action :
                <Menus.Popover>
                    <LinkWithIcon
                        route       = { "EXHIBITION_DETAILS_FULL" }
                        routeParams = { { eeId : id } }
                        icon={ "Edit" }
                        className={ "link-text" }
                    >
                        <Localize>GLOBAL.Action_Menu_Edit</Localize>
                    </LinkWithIcon>
                    <DeleteButton
                        className  = { "exhibition-delete" }
                        buttonType = { "text"  }
                        id         = { Number(id) }
                        query      = { EXHIBITION_DELETE }
                        reFetch    = {[ "GetExhibitions" ]}
                        confirmText= {
                            localizeByKey(
                                'EXHIBITION.Modal_Text_DeleteExhibition',
                                { exhibitionTitle : title }
                            )
                        }
                    >
                        <Localize>GLOBAL.Action_Menu_Delete</Localize>
                    </DeleteButton>
                </Menus.Popover>
        };
    });
};

const ExhibitionsTableHelper = { columns, data };

export default ExhibitionsTableHelper;