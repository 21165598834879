import React, { JSX, useEffect, useState } from "react";

import { message } from "antd";
import cache from "graphql/cache";

import Localize, { localizeByKey } from "../../service/Localize";
import GraphicsButtons from "../buttons";
import GraphicsForms from "../forms";
import GraphicsLists from "../lists";
import GraphicsModals from "../modals";
import { Menus } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataBlockProps {
    printDataState : [
        values : TGraphicsPrintData[],
        set : ( values : TGraphicsPrintData[] ) => void
    ]
    orderId : TID
    closeDrawer : () => void
}

const GraphicsPrintDataBlock : React.FC<IGraphicsPrintDataBlockProps> = (
    {
        orderId,
        printDataState,
        closeDrawer
    }) : JSX.Element => {

    const [ printData, setPrintData  ] = printDataState;
    const [ messageApi, contextHolder ] = message.useMessage();

    useEffect(() => {
        cache.modify({
            id : cache.identify({ __ref : `GraphicsOrder:${ orderId }` }),
            fields : {
                relGraphicsPrintDatas() {
                    return printData.map(( { id } ) => {
                        return { '__ref' : `GraphicsPrintData:${ id }` };
                    });
                },
            },
        });
    }, [ printData ]);

    const textData = printData.find(({ type }) => type === 'text');

    const [ textExist, setTextExist ] = useState<boolean>(!!textData);

    const printDataFiles = printData.filter(
        ({ type }) => [ 'link', 'file' ].includes(type)
    );

    return(
        <div className="graphics-print-data-block">
            <div className="print-data-type file">
                <div className="header">
                    <Localize wrap tag = "h4">
                        GRAPHICS.Block_Title_PrintingFiles
                    </Localize>
                    <div className="actions">
                        <Menus.Popover
                            iconText = { localizeByKey('GLOBAL.Button_Label_Add') }
                            iconName = { 'Plus' }
                            btnClass = { 'inverted' }
                        >
                            <GraphicsButtons.PrintData.AddFile
                                orderId        = { orderId }
                                printDataState = { printDataState }
                                messageApi     = { messageApi }
                            />
                            <GraphicsModals.PrintDataLink
                                orderId        = { orderId }
                                printDataState = { printDataState }
                            />
                        </Menus.Popover>
                    </div>
                </div>
                { !!printDataFiles.length &&
                    <div className="data">
                        <GraphicsLists.PrintDataFile
                            printData = { printDataFiles }
                            onDelete  = { (fileId) => {
                                setPrintData(
                                    printData.filter(( { id }) => id !== fileId)
                                );
                            }}
                        />
                    </div>
                }
            </div>
            <div className="print-data-type text">
                <div className="header">
                    <Localize wrap tag = "h4">
                        GRAPHICS.Block_Title_PrintingText
                    </Localize>
                    <div className="actions">
                        <GraphicsButtons.PrintData.AddDeleteText
                            textId       = { textData?.id }
                            textExist    = { textExist }
                            setTextExist = { setTextExist }
                            onDelete     = {
                                (textId) => {
                                    setPrintData(
                                        printData.filter(( { id }) => id !== textId)
                                    );
                                }
                            }
                        />
                    </div>
                </div>
                { textExist &&
                    <div className="data">
                        <GraphicsForms.PrintDataTextEdit
                            orderId  = { orderId }
                            textData = { textData }
                            onUpdate = {
                                (printDataItem) =>  {
                                    if(textData){
                                        setPrintData(
                                            printData.map(( item ) => {
                                                return item.id === printDataItem.id ? printDataItem : item;
                                            })
                                        );
                                    } else {
                                        setPrintData([ ...printData, printDataItem ] );
                                    }

                                    closeDrawer();
                                }
                            }
                        />
                    </div>
                }
            </div>
            {contextHolder}
        </div>
    );
};

export default GraphicsPrintDataBlock;