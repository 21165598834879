import React, { JSX } from "react";

import { Icons } from "components/layout";
import Localize, { localizeByKey } from "components/service/Localize";


type TTaskImportanceHelperProps = {
    name: string,
    onlyIcon?: boolean
};

const taskImportanceClassHelper = (name ?: string) => {
    switch (name){
        case 'task_importance_low'       : return 'low';
        case 'task_importance_high'      : return 'high';
        case 'task_importance_instantly' : return 'instantly';
        default                          : return 'normal';
    }
};

const TaskImportanceHelper : React.FC<TTaskImportanceHelperProps> = ({
        name = "",
        onlyIcon = true
    }) : JSX.Element => {
    return (
        <div
            className={ `importance ${ taskImportanceClassHelper(name) }` }
            title={ localizeByKey( `VARIABLE_ITEM.${name}` ) }
        >
            <Icons.Importance />
            { !onlyIcon &&
                <Localize>{ `VARIABLE_ITEM.${name}` }</Localize>
            }
        </div>
    );
};

export default TaskImportanceHelper;