import TaskBlocks from "./blocks";
import TaskButtons from "./buttons";
import TaskFields from "./fields";
import TaskForms from "./forms";
import TaskHelpers from "./helpers";
import TaskLists from "./lists";
import TaskMenus from "./menus";
import TaskModals from "./modals";
import TaskTables from "./table";

const Task  = {
    Forms : TaskForms,
    Blocks : TaskBlocks,
    Fields : TaskFields,
    Buttons : TaskButtons,
    Modals : TaskModals,
    Lists : TaskLists,
    Menus : TaskMenus,
    Helpers : TaskHelpers,
    Tables : TaskTables,
};


export default Task;