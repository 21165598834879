import React, { JSX } from "react";

import { Input } from "antd";
import { TID } from "graphql/type/common-gql-types";

import GraphicsFields from "../fields";
import { EGraphicsFilterFieldTypes, type TGraphicsFilters } from "../graphics-types";
import GraphicsHelpers from "../helpers";
import Exhibition from "components/exhibition";
import { Blocks, Fields } from "components/layout";


type TExhibitionListFilterProps = {
    eeId        : TID
    filters     : TGraphicsFilters,
    setFilters  : React.Dispatch<React.SetStateAction<TGraphicsFilters>>
    callBack   ?: () => void
};

const GraphicsListFilterForm : React.FC<TExhibitionListFilterProps> = (
    {
        eeId,
        filters,
        setFilters,
        callBack = () => {},
    }) : JSX.Element => {

    return (
        <Blocks.Filter.Form
            filterFields     = { GraphicsHelpers.filter.parse(filters) }
            fieldTypes       = { EGraphicsFilterFieldTypes }
            defaultFiledType = { EGraphicsFilterFieldTypes.NONE }
            translationKey   = { 'GRAPHICS.Select_Option_Filter' }
            onReset={
                () => {
                    setFilters({ text : '' });
                    callBack();
                }
            }
            onApply={
                (fields) => {
                    setFilters({
                        text : filters.text,
                        ...GraphicsHelpers.filter.build(fields)
                    });
                    callBack();
                }
            }
        >
            {
                ({
                     fieldSettings,
                     filteredTypes,
                     fieldItem : { type, value/*,  label */ }
                 }) => {

                    switch (type){
                        case EGraphicsFilterFieldTypes.BLOCK:
                        case EGraphicsFilterFieldTypes.STAND_NUMBER:
                        case EGraphicsFilterFieldTypes.COMPANY_NAME:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Input />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.HALL:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Exhibition.Fields.HallSelect
                                        eeId  = { Number(eeId) }
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.MP_STATUS:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Fields.FromVariablesSelect
                                        variableGroup  = {'mp status'}
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.STATUS:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes> { ...fieldSettings }>
                                    <Fields.FromVariablesSelect
                                        variableGroup  = {'graphic status'}
                                    />
                                </Blocks.Filter.Item>
                            );
                        case EGraphicsFilterFieldTypes.NONE:
                        default:
                            return (
                                <Blocks.Filter.Item<EGraphicsFilterFieldTypes>
                                    { ...fieldSettings }
                                    noValue = { true }
                                >
                                    <GraphicsFields.FilterTypeSelect
                                        filteredTypes = { filteredTypes as EGraphicsFilterFieldTypes[]}
                                        defaultValue  = {
                                            value && value.length ?
                                                value as EGraphicsFilterFieldTypes :
                                                type as EGraphicsFilterFieldTypes
                                        }
                                    />
                                </Blocks.Filter.Item>
                            );
                    }
                }}
        </Blocks.Filter.Form>
    );
};

export default GraphicsListFilterForm;