import React from "react";

import {  TStandItem } from "graphql/type/stand-gql-types";
import { generatePath, Link } from "react-router-dom";

import StandMenus from "../menus";
import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { TagStandard } from "components/service";
import Localize from "components/service/Localize";
import Task from "components/task";

import type { TLocales } from "common/types";
import type { TNode } from "graphql/type/common-gql-types";
import type { TStandTypeRelation } from "graphql/type/stand-type-gql-types";
import type { FixedType } from "rc-table/lib/interface";


const columns  = [
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_GeneralInfo
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_Company
                </Localize>
            </>
        ,
        dataIndex : "title",
        columnIndex : "TITLE",
        width : 240,
        fixed : 'left' as FixedType,
        className : "general-info"
        //sorter: true,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_Hall
            </Localize>,
        dataIndex : "hall",
        columnIndex : "HALL_NUMBER",
        className : "general-info",
        width : 120,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_Block
            </Localize>,
        dataIndex : "block",
        columnIndex : "BLOCK_NUMBER",
        className : "general-info",
        width : 120,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_StandNumber
            </Localize>,
        dataIndex : "standNumber",
        columnIndex : "STAND_NUMBER",
        className : "general-info",
        width : 80,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_PROAUF
            </Localize>,
        dataIndex : "proauf",
        columnIndex : "PROAUF_NUMBER",
        className : "general-info",
        width : 90,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_StandType
            </Localize>,
        dataIndex : "standType",
        columnIndex : "STAND_TYPE",
        className : "general-info",
        width : 260,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_Orientation
            </Localize>,
        dataIndex : "standOrientation",
        columnIndex : "ORIENTATION",
        className : "general-info",
        width : 130,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_Size
            </Localize>,
        dataIndex : "size",
        columnIndex : "SQUARE_METER",
        className : "general-info",
        width : 100,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_FloorCovering
            </Localize>,
        dataIndex : "floorCovering",
        columnIndex : "FLOOR_COVERING",
        className : "general-info",
        width : 180,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_ProjectManager
            </Localize>,
        dataIndex : "projectManager",
        columnIndex : "PROJECT_MANAGER_ID",
        className : "general-info",
        width : 140,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_AutocadEngineer
            </Localize>,
        dataIndex : "autocadEngineer",
        columnIndex : "AUTOCAD_ENGINEER",
        className : "general-info",
        width : 140,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_Comments
            </Localize>,
        dataIndex : "commentsCount",
        columnIndex : "COMMENTS_COUNT",
        className : "general-info",
        width : 90,
    },
    {
        title :

            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_Equipment
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_AdditionalOrder
                </Localize>
            </>,
        dataIndex : "additionalOrder",
        columnIndex : "ADDITIONAL_ORDER",
        className : "equipment",
        width : 280,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_EquipmentComment
            </Localize>,
        dataIndex : "equipmentComment",
        columnIndex : "EQUIPMENT_COMMENT",
        className : "equipment",
        width : 260,
    },
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_Graphics
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_OrdersNumber
                </Localize>
            </>,
        dataIndex : "ordersNumber",
        columnIndex : "ORDERS_NUMBER",
        className : "graphics",
        width : 200,
    },
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_CADInfo
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_CADNumber
                </Localize>
            </>,
        dataIndex : "cadNumber",
        columnIndex : "CAD_NUMBER",
        className : "cad-info",
        width : 100,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_CADStatus
            </Localize>,
        dataIndex : "cadStatus",
        columnIndex : "CAD_STATUS",
        className : "cad-info",
        width : 180,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_CadChanges
            </Localize>,
        dataIndex : "cadChanges",
        columnIndex : "CAD_CHANGES",
        className : "cad-info",
        width : 80,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_CadVersion
            </Localize>,
        dataIndex : "cadVersion",
        columnIndex : "CAD_VERSION",
        className : "cad-info",
        width : 150,
    },
    {
        title :
            <>
                <Localize wrap tag="div" className="table-section-label">
                    STAND.TableColumn_Group_CurrentTask
                </Localize>
                <Localize>
                    STAND.TableColumn_Header_Importance
                </Localize>
            </>,
        dataIndex : "taskImportance",
        columnIndex : "TASK_IMPORTANCE",
        className : "task-info",
        width : 100,
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_AssignedTo
            </Localize>,
        dataIndex : "taskAssignedTo",
        columnIndex : "TASK_ASSIGNED_TO",
        className : "task-info",
        width : 140
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_TaskStatus
            </Localize>,
        dataIndex : "taskStatus",
        columnIndex : "TASK_STATUS",
        className : "task-info",
        width : 140
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_TaskType
            </Localize>,
        dataIndex : "taskType",
        columnIndex : "TASK_TYPE",
        className : "task-info",
        width : 140
    },
    {
        title :
            <Localize>
                STAND.TableColumn_Header_TaskDetails
            </Localize>,
        dataIndex : "task",
        columnIndex : "TASK",
        className : "task-info",
        width : 300
    },
    {
        dataIndex : "action",
        width : 60,
        fixed : 'right' as FixedType,
        className : "actions"

    }
];


const dataMap = ( stands : TNode<TStandItem<TStandTypeRelation>>[], lang : TLocales, ) => {

    return stands.map( ({ node  }) => {

        const {
            id,
            ee_id,
            company_name,
            relStandType,
            relStandCommentEquipment,
            relAutocadEngineer,
            relProjectManager,
            relGraphicsOrders,
            relStandEquipmentOrders,
            size_width,
            size_height,
            square_meter,
            relCad,
            relTask : task,
        } = node;

        const [ standType ] = relStandType,
              orientation = standType.orientations.find(
                  ( { orientation_key } ) => orientation_key === node.orientation_key
              );

        const additionalOrders = relStandEquipmentOrders?.filter(
            (order) => order.set_type !== 'default'
        );

        const graphicsOrders = [ ...relGraphicsOrders || [] ].sort(
            ( itemA, itemB ) => {
                if (itemA.number > itemB.number){
                    return 1;
                }

                return -1;
            }
        );

        return{
            key : id,
            title :
                <Link className="item-link" to={
                    generatePath(
                        ROUTES.STAND_ITEM_FULL,
                        {
                            eeId : Number(ee_id),
                            standId : Number(id)
                        }
                    )
                }>
                    { company_name }
                </Link>,
            hall : node.hall_title || <>—</>,
            block : node.block || <>—</>,
            standNumber : node.stand_number || <>—</>,
            proauf : <p className="proauf">{ node.proauf || <>—</>}</p>,
            standType : standType.title,
            standOrientation : orientation ?
                <div className="orientation">
                    <span className="img-wrap">
                        <img src={ orientation!.img_path } alt={`${ orientation!.orientation_key } image`}/>
                    </span>
                    <Localize wrap className="title">
                        {`VARIABLE_ITEM.${ orientation!.orientation_key }`}
                    </Localize>
                </div> : <>—</>,
            size : `${square_meter} (${size_width}x${size_height})`,
            floorCovering : node.flooring_title || <>—</>,
            projectManager : relProjectManager ? `${relProjectManager.name} ${relProjectManager.surname}` : <>—</>,
            autocadEngineer : relAutocadEngineer  ? `${relAutocadEngineer.name} ${relAutocadEngineer.surname}` : <>—</>,
            commentsCount :
                <>
                    <Icons.Comment/>
                    {node.total_comment || 0}
                </>,
            additionalOrder :
                additionalOrders && !!additionalOrders.length ?
                    <div className="additional-orders">
                        {additionalOrders.slice(0, 2).map((order) => {

                            const equipment = order.relEquipment.find(
                                (item) => item.lang === lang
                            );

                            if(!equipment) return null;

                            return(
                                <p key={order.id}>
                                    <span className="amount">{order.qty}x</span> {equipment.title}
                                </p>
                            );
                        })}
                        { additionalOrders.length - 2 > 0 &&
                            <p className="more" key="more">
                                <Localize wrap count={ additionalOrders.length - 2 }>
                                    GLOBAL.Label_Text_More
                                </Localize>
                                <Link className="table-link" to={
                                    generatePath(
                                        ROUTES.STAND_ITEM_EQUIPMENT_FULL,
                                        {
                                            eeId : Number(ee_id),
                                            standId : Number(id)
                                        }
                                    )
                                }>
                                    <Localize>
                                        NAVIGATION.Button_Text_View
                                    </Localize>
                                </Link>
                            </p>
                        }
                    </div> : <>—</>,
            equipmentComment :
                relStandCommentEquipment && relStandCommentEquipment.comment !== null ? relStandCommentEquipment.comment : <>—</>
            ,
            ordersNumber : (graphicsOrders && !!graphicsOrders.length ) ?
                <div className="graphics-orders">
                    <p className="count">
                        <b>{graphicsOrders.length}</b>
                    </p>
                    <div className="order-list">
                        {graphicsOrders.map(
                            (order, idx) => {

                                return (
                                    <Link
                                        key={order.id}
                                        to={
                                            generatePath(
                                                ROUTES.STAND_ITEM_GRAPHIC_FULL,
                                                {
                                                    orderId : order.id,
                                                    eeId : Number(ee_id),
                                                    standId : Number(id)
                                                }
                                            )
                                        }
                                    >
                                        {order.number}.{' '}
                                        <Localize>{`VARIABLE_ITEM.${order.status}`}</Localize>
                                        {graphicsOrders.length > 1 && idx + 1 !== graphicsOrders.length ? ', ' : ''}
                                    </Link>
                                );
                            }
                        )}
                    </div>
                </div>
                : <>—</>,
            cadNumber : relCad ? relCad.cad_number : <>—</>,
            cadStatus : relCad ?
                <TagStandard className={Task.Helpers.status(relCad.status)}>
                    <Localize>{`VARIABLE_ITEM.${ relCad.status }`}</Localize>
                </TagStandard> :  <>—</>,
            cadChanges : relCad ? relCad.correction :  <>—</>,
            cadVersion : relCad ?
                <div className="cad-version>">
                    <Localize versionNumber={`${relCad.version}.${relCad.correction}`}>
                        GLOBAL.Label_Text_Version
                    </Localize> <br/>
                    {relCad.updated_at}
                </div> :
                <>—</>,
            taskImportance : task ?
                <Task.Helpers.Importance name={ task.importance } onlyIcon={false} /> : <>—</>,
            taskAssignedTo : task && task.relAssignedTo  ?
                <>{task.relAssignedTo.name} {task.relAssignedTo.surname}</> : <>—</>,
            taskStatus : task ?
                <TagStandard className={Task.Helpers.status(task.status)}>
                    <Localize>{`VARIABLE_ITEM.${ task.status }`}</Localize>
                </TagStandard>
                : <>—</>,
            taskType : task ?
                <Localize wrap tag="p" className="type text">
                    {`VARIABLE_ITEM.${ task.type }`}
                </Localize>
                : <>—</>,
            task : task ?
                <>
                    <span>{ task.title }</span>
                    <Link className="table-link" to={
                        generatePath(
                            ROUTES.STAND_ITEM_TASK_FULL,
                            {
                                taskId : Number(task.id),
                                eeId : Number(ee_id),
                                standId : Number(id)
                            }
                        )
                    }>
                        <Localize>
                            NAVIGATION.Button_Text_View
                        </Localize>
                    </Link>
                </>
                : <>—</>,
            action :
                <StandMenus.TableItem
                    eeId        = { ee_id }
                    standId     = { id }
                    companyName = { company_name }
                />
        };

    });
};

const StandTableHelper = {
    columns,
    totalColumnWidth : () : number => {
        return columns.reduce( (total, item) => total + item.width + 3, 0) + 20;
    },
    dataMap,
};

export default StandTableHelper;