import React, { JSX } from "react";

import { Select } from "antd";

import useGraphicsSupplierFragment from "../hooks/useGraphicsSupplierFragment";
import { Icons } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";


const { Option } = Select;

interface IGraphicsProductTypeSelectProps {
    supplierId    : TID
    disabled     ?: boolean
}

const GraphicsProductTypeSelect : React.FC<IGraphicsProductTypeSelectProps> = (
    {
        supplierId,
        ...props
    }) : JSX.Element => {

    const { relRule } = useGraphicsSupplierFragment( { supplierId }) || {};

    return(
        <Select
            { ...props }
            defaultValue={ relRule && relRule.length ? relRule[0].id : '' }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { !relRule && <Option key={ 0 } value={ "" }>—</Option> }
            { relRule && relRule.map(
                ({ id, title  }) => {
                    return(
                        <Option key={ id } value={ id } >{ title }</Option>
                    );
                })
            }
        </Select>
    );
};

export default GraphicsProductTypeSelect;