import { EExhibitionFilterFieldTypes } from "../exhibition-types";

import type { TExhibitionFilterGroups, TExhibitionFilters } from "../exhibition-types";
import type { TFilterFieldItem, TFilterFields } from "common/types";
import type { TWhereCondition } from "graphql/type/common-gql-types";


const exhibitionFilterParse = (filters : TExhibitionFilters) : TFilterFieldItem<EExhibitionFilterFieldTypes>[] => {

    const fields : TFilterFieldItem<EExhibitionFilterFieldTypes>[] = [];

    (Object.keys(filters) as Array<TExhibitionFilterGroups | 'text'>).forEach( ( group ) => {

        if(group !== 'text'){
            const filterGroup = filters[group] as Omit<TFilterFields, 'text'>;

            Object.keys(filterGroup).forEach(( filterItemKey ) => {

                const item = filterGroup[filterItemKey] as TWhereCondition;
                let type;

                switch(`${ group }_${filterItemKey}`){
                    case 'where_projectManager':  type = EExhibitionFilterFieldTypes.PROJECT_MANAGER; break;
                    case 'where_status' :         type = EExhibitionFilterFieldTypes.STATUS; break;
                    case 'where_organizer':       type = EExhibitionFilterFieldTypes.ORGANIZER; break;
                    case 'where_exhibitionType':  type = EExhibitionFilterFieldTypes.EXHIBITION_TYPE; break;
                    case 'where_team':            type = EExhibitionFilterFieldTypes.TEAM; break;
                }

                const value = typeof item.value === 'string' ? item.value.replace('%', '') : item.value;

                fields.push({
                    key : Date.now().toString(),
                    type : type!,
                    saveable : true,
                    label : item.label,
                    value : value as string
                });
            });
        }
    });

    return fields;
};

const exhibitionFilterBuild = (fields : TFilterFieldItem<EExhibitionFilterFieldTypes>[]): Omit<TExhibitionFilters, 'text'> => {

    const where: TExhibitionFilters["where"] = {};


    fields.forEach( (item) => {

        if(item.saveable && item.value !== '') {
            const filter : TWhereCondition = {
                column : '',
                operator : "EQ",
                value : item.value,
                label : item.label
            };

            switch( item.type ){
                case EExhibitionFilterFieldTypes.PROJECT_MANAGER:
                    where[item.type] = { ...filter, column : 'PROJECT_MANAGER_ID' };
                    break;
                case EExhibitionFilterFieldTypes.ORGANIZER:
                    where[item.type] = { ...filter, column : 'ORGANIZER_ID' };
                    break;
                case EExhibitionFilterFieldTypes.EXHIBITION_TYPE:
                    where[item.type] = { ...filter, column : 'EXHIBITION_TYPE_ID' };
                    break;
                case EExhibitionFilterFieldTypes.TEAM:
                    where[item.type] = { ...filter, column : 'TEAM_ID' };
                    break;
                case EExhibitionFilterFieldTypes.STATUS:
                    where[item.type] = { ...filter, column : 'STATUS' };
                    break;
            }
        }
    });

    return {
        ...(Object.keys(where).length > 0 && { where }),
    };
};


const exhibitionFilterHelper = {
    parse : exhibitionFilterParse,
    build : exhibitionFilterBuild
};

export default exhibitionFilterHelper;