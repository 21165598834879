import React, { JSX, useState } from "react";

import { Button } from "antd";

import EquipmentBlocks from "../blocks";
import { TFilterProps } from "../blocks/Equipment-filter-block";
import EquipmentHooks from "../hooks";
import EquipmentLists from "../lists";
import { Icons } from "components/layout";
import { DrawerStandard } from "components/service/";
import Localize from "components/service/Localize";

import type { TID } from "graphql/type/common-gql-types";
import type { TEquipmentItem, TEquipmentOrderType } from "graphql/type/equipment-gql-types";


interface EquipmentSelectModalProps {
    standId           : TID
    equipmentGroupId ?: TID
    orderId          ?: TID
    etId              : TID
    orgId             : TID
    type              : TEquipmentOrderType
    isSubstitute     ?: boolean
    btnClass         ?: string
}

const EquipmentSelectDrawer : React.FC<EquipmentSelectModalProps> = ({
      standId,
      etId,
      orgId,
      equipmentGroupId,
      orderId,
      type,
      isSubstitute = true,
      btnClass = 'blue',
    }) : JSX.Element => {


    const { equipmentAction, loading } = EquipmentHooks.useAddChange({
        isSubstitute
    });

    const [ openDrawer, setOpenDrawer ] = useState( false );
    const [ equipment, setEquipment ] = useState<TEquipmentItem | undefined>();

    return (
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => {
                    setOpenDrawer(true);
                }}
            >
                { isSubstitute ? <Icons.Substitute /> : <Icons.Plus /> }
                <Localize wrap>
                    { isSubstitute ? 'EQUIPMENT.Action_Menu_Substitute' : 'EQUIPMENT.Button_Text_AddEquipment' }
                </Localize>

            </Button>
            <DrawerStandard
                title      = {
                    <Localize wrap tag="h2">
                        { isSubstitute ? 'EQUIPMENT.Modal_Title_Substitute' : 'EQUIPMENT.Modal_Title_AddEquipment' }
                    </Localize>
                }
                isOpen     = { openDrawer }
                extraClass = { "select-modal radio" }
                close      = { () => setOpenDrawer(false) }

                extra={
                    <div className="form-actions">
                        <Button onClick={ () => setOpenDrawer(false)  }>
                            <Localize wrap>GLOBAL.Button_Label_Cancel</Localize>
                        </Button>
                        <Button loading = { loading }
                                type    = "primary"
                                onClick = { () => {
                                    if(equipment && !loading){
                                        equipmentAction({
                                            variables : {
                                                standId,
                                                type,
                                                orderId,
                                                equipmentGroupId : equipment.group_id
                                            }
                                        });
                                    }

                                    setOpenDrawer(false);
                                }}
                        >
                            <Localize wrap>GLOBAL.Button_Label_Choose</Localize>
                        </Button>
                    </div>
                }
            >
                <EquipmentBlocks.Filter
                    filterProps = {{
                        etId,
                        orgId
                    }}
                >
                    { (filteredProps : TFilterProps) => (
                        <EquipmentLists.Radio
                            groupId      = { equipmentGroupId }
                            equipment    = { equipment }
                            setEquipment = { setEquipment }
                            { ...filteredProps}
                        />
                    ) }
                </EquipmentBlocks.Filter>
            </DrawerStandard>
        </>

    );
};

export default EquipmentSelectDrawer;