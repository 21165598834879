import React, { JSX } from "react";

import { Button } from "antd";
import { SelectProps } from "rc-select/lib/Select";

import Icons from "../../icons";
import { cloneChildren } from "common/utils";

import type { TFilterFieldItem } from "common/types";

interface IFieldItemProps<FieldTypes> {
    itemKey         : string
    label           : string
    isFieldSelector : boolean
    fields          : TFilterFieldItem<FieldTypes>[]
    setFields       : ( fields : TFilterFieldItem<FieldTypes>[] ) => void
    onChange        : SelectProps['onChange']
    children        : React.ReactNode
    noValue        ?: boolean
}

const FilterItemBlock = <FieldTypes ,>(
    {
        itemKey,
        label,
        isFieldSelector,
        setFields,
        fields,
        onChange,
        children,
        noValue = false
    } : IFieldItemProps<FieldTypes>): JSX.Element => {

    const filterItem = fields.find(
        ({ key : filteredKey }, fieldIdx) => `${filteredKey}_${fieldIdx}` === itemKey
    );

    return (
        <div className="filter-item-block" key={itemKey}>
            <div>
                <div className="label">{ label }</div>
                <div className="action">
                    <Button
                        type="text"
                        onClick={() => {
                            setFields(
                                fields.filter(
                                    ({ key : filteredKey }, fieldIdx) => `${filteredKey}_${fieldIdx}` !== itemKey
                                )
                            );
                        }}
                    >
                        <Icons.Delete/>
                    </Button>
                </div>
            </div>
            <div>
                { cloneChildren(
                    children as React.ReactElement,
                    {
                        onChange,
                        ...(!noValue && { value : filterItem?.value } )
                    }
                )}
                { isFieldSelector && filterItem && filterItem.value !== '' &&
                    <Button
                        type="default"
                        onClick={() => {
                            setFields(
                                fields.map(
                                    (item, fieldIdx) => {
                                        return (`${item.key}_${fieldIdx}` === itemKey && item.value) ?
                                            {
                                                ...item,
                                                type : item.value as FieldTypes,
                                                value : '',
                                                saveable : true,
                                            } : item;
                                    }
                                )
                            );
                        }}
                    >
                        Confirm
                    </Button>
                }
            </div>
        </div>
    );
};

export default FilterItemBlock;