import React, { JSX } from "react";

import { GRAPHICS_LAYOUT_DELETE } from "graphql/mutation/graphics-m.gql";

import { getDate } from "common/utils";
import { Icons } from "components/layout";
import { DeleteButton } from "components/service";
import { localizeByKey } from "components/service/Localize";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsLayout } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataFileListProps {
    layouts  ?: TGraphicsLayout[]
    onDelete   ?: ( fileId : TID ) => void
}

const GraphicsLayoutFileList : React.FC<IGraphicsPrintDataFileListProps> = (
    {
        layouts = [],
        onDelete  = () => {},
    }): JSX.Element | null => {

    if(!layouts.length){
        return null;
    }

    return (
        <div className="graphics-layout-file-list-wrap">
            <ul  className="graphics-layout-file-list">
                { layouts.map(
                    ( data ) => {
                        return (
                            <li
                                key       = { data.id }
                                className = "graphics-layout-file-list-item"
                            >
                                <div className="name">
                                    <span>{ data.title } ({ getDate.formated(data.created_at) })</span>
                                </div>
                                <div className="actions">
                                    <a className  = "link-text inverted"
                                       href       = { data.getPathPublic }
                                       download
                                       target     = "_blank"
                                       aria-label ="download"
                                    >
                                        <Icons.Download/>
                                    </a>
                                    <DeleteButton
                                        id         = { data.id }
                                        buttonType = { "text" }
                                        iconName   = { "Delete" }
                                        query      = { GRAPHICS_LAYOUT_DELETE }
                                        onSuccess  = {(_, cache) => {
                                            cache.evict({
                                                id : cache.identify(
                                                    { id : data.id, __typename : "GraphicsLayout" }
                                                )
                                            });

                                            onDelete(data.id);
                                        }}
                                        confirmText = { localizeByKey(
                                            'GLOBAL.Modal_Text_DeleteFile',
                                            {
                                                br : `\n`,
                                                file : `${data.server_name}.${data.extension}`
                                            }
                                        )}
                                    />
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default GraphicsLayoutFileList;