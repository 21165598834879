import React, { JSX, useState } from "react";

import { Button, FormInstance } from "antd";

import Localize, { localizeByKey } from "../../service/Localize";
import GraphicsForms from "../forms";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TGraphicsPrintData } from "graphql/type/graphics-gql-types";


interface IGraphicsPrintDataLinkModalProps {
    orderId     : TID
    linkData   ?: TGraphicsPrintData
    printDataState : [
        values : TGraphicsPrintData[],
        set : ( values : TGraphicsPrintData[] ) => void
    ]
    btnClass   ?: string
}

const GraphicsPrintDataLinkModal: React.FC<IGraphicsPrintDataLinkModalProps> = (
    {
        btnClass = "",
        printDataState,
        ...props
    }) : JSX.Element => {

    const [ openModal, setOpenModal ] = useState( false ),
          [ form, setForm ] = useState<FormInstance | undefined>(undefined);

    const [ printData, setPrintData ] = printDataState;

    return(
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenModal(true) }
            >
                { props.linkData ? <Icons.Edit /> : <Icons.Link />  }
                { !props.linkData ? localizeByKey('GRAPHICS.Action_Menu_AddFileLink') : null }
            </Button>
            <ModalStandard
                title      = {
                    <Localize wrap tag="h2">
                        { props.linkData ? 'GRAPHICS.Modal_Title_EditLink' : 'GRAPHICS.Modal_Title_AddLink' }
                    </Localize>
                }
                isOpen     = { openModal }
                extraClass = { "graphics-print-data-link-modal" }
                close      = { () => {
                    setOpenModal(false);
                }}
                okText     = {
                    localizeByKey(
                        props.linkData ? 'GLOBAL.Button_Label_Save' : 'GLOBAL.Button_Label_Add'
                    )
                }
                confirm    = {
                    () => {
                        form && form.submit();
                    }
                }
            >
                <GraphicsForms.PrintDataLinkEdit
                    { ...props }
                    onUpdate = {
                        (link) => {
                            setPrintData([
                                ...printData,
                                { ...link }
                            ]);
                            setOpenModal(false);
                        }
                    }
                    setForm  = { setForm }
                />
            </ModalStandard>
        </>
    );
};

export default GraphicsPrintDataLinkModal;